import type { FieldPath, FieldValues } from "react-hook-form";
import type { ReactQuillProps } from "react-quill";

import type { CarouselProps } from "@components/Carousel/Carousel";
import type { DropzoneProps } from "@components/Dropzone/Dropzone";
import type { FileUploaderProps } from "@components/FileUploader/FileUploader";
import type { IFormListInput } from "@components/Inputs/MultiSelectInput";
import type { CheckboxProps, SelectProps, TextFieldProps } from "@mui/material";
import { type InputBaseProps } from "@mui/material/InputBase";
import { type SelectVariants } from "@mui/material/Select/Select";
import { type TextFieldPropsSizeOverrides } from "@mui/material/TextField/TextField";
import { type Theme } from "@mui/material/styles";
import { type SxProps } from "@mui/system";
import { type OverridableStringUnion } from "@mui/types";
import type { DatePickerProps } from "@mui/x-date-pickers";
import type { AssetContentFieldStatus, BOOLEAN_MODE } from "@src/types";

export type Credentials = {
  accessToken: string;
  idToken: string;
  apiKey: string;
};

export type SelectOptionType = {
  label: string;
  value: string | number;
  benefit?: boolean;
};

export enum METHODS {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export enum MODAL_ACTION_TYPES {
  NONE = "NONE",
  DELETE_PRODUCT = "DELETE_PRODUCT",
  DELETE_ASSET = "DELETE_ASSET",
  UNLINK_ASSET = "UNLINK_ASSET",
  ADD_PRODUCT = "ADD_PRODUCT",
  DUPLICATE_PRODUCT = "DUPLICATE_PRODUCT",
  DUPLICATE_ASSET = "DUPLICATE_ASSET",
  CANCEL = "CANCEL",
  UNDO_CANCEL = "UNDO_CANCEL",
  SET_WAITING_FOR_APPROVAL = "SET_WAITING_FOR_APPROVAL",
  UNSET_WAITING_FOR_APPROVAL = "UNSET_WAITING_FOR_APPROVAL",
  CLOSE_BRIEF = "CLOSE_BRIEF",
  REOPEN_BRIEF = "REOPEN_BRIEF",
  DELIVER_ASSET = "DELIVER_ASSET",
  UNDELIVER_ASSET = "UNDELIVER_ASSET",
}

export enum FIELD_TYPES {
  BOOLEAN,
  SELECT,
  INPUT_TEXT,
  INPUT_NUMBER,
  DATE,
  DROP_ZONE_CAROUSEL,
  CLASSIC_CAROUSEL,
  FILE,
  MULTI_SELECT,
  SELECT_SEARCH,
  RICH_TEXT,
  FIELD_ARRAY,
}

export type MultiSelectInputOptions =
  | string[]
  | { label: string; value: string }[];

export enum FieldLayout {
  DISSOCIATED_LABEL,
  ONLY_PLACEHOLDER,
}

export type FieldConfig<T extends FieldValues> = {
  fieldName: FieldPath<T>;
  hide?: boolean;
  fieldLabel?: string;
  mandatory?: boolean;
  muiVariant?: SelectVariants;
  inputProps?: InputBaseProps["inputProps"];
  sx?: SxProps<Theme>;
  layout?: FieldLayout;
  size?: OverridableStringUnion<
    "small" | "medium",
    TextFieldPropsSizeOverrides
  >;
  autoFocus?: boolean;
} & (
  | ({
      fieldType: FIELD_TYPES.SELECT;
      options: SelectOptionType[];
    } & SelectProps)
  | ({
      fieldType: FIELD_TYPES.SELECT_SEARCH;
      options: SelectOptionType[];
    } & SelectProps)
  | ({ fieldType: FIELD_TYPES.DATE } & DatePickerProps<any> & {
        status?: AssetContentFieldStatus;
      })
  | ({ fieldType: FIELD_TYPES.INPUT_TEXT } & TextFieldProps & {
        status?: AssetContentFieldStatus;
      })
  | ({ fieldType: FIELD_TYPES.INPUT_NUMBER } & TextFieldProps & {
        status?: AssetContentFieldStatus;
      })
  | ({
      fieldType: FIELD_TYPES.DROP_ZONE_CAROUSEL;
      nbElementsPerView?: number;
    } & Partial<CarouselProps> &
      Partial<DropzoneProps>)
  | ({ fieldType: FIELD_TYPES.CLASSIC_CAROUSEL } & Partial<CarouselProps> &
      Partial<DropzoneProps>)
  | ({ fieldType: FIELD_TYPES.FILE } & Partial<FileUploaderProps>)
  | ({
      fieldType: FIELD_TYPES.MULTI_SELECT;
      options: MultiSelectInputOptions;
      noStatus?: boolean;
    } & Partial<IFormListInput<FieldValues>>)
  | ({
      fieldType: FIELD_TYPES.BOOLEAN;
      mode?: BOOLEAN_MODE;
    } & CheckboxProps)
  | ({
      fieldType: FIELD_TYPES.RICH_TEXT;
    } & Partial<RichTextEditorProps> & { status?: AssetContentFieldStatus })
  | {
      fieldType?: FIELD_TYPES.FIELD_ARRAY;
      subFields: FieldConfig<T>[];
    }
);

export interface RichTextEditorProps extends ReactQuillProps {
  label: string;
  error?: string;
  status?: AssetContentFieldStatus;
  disabled?: boolean;
  maxHeight?: number;
}

export type ApiError = {
  context: string;
  errorCode: string;
  errorName: string;
  messages?: string[];
  method: string;
  path: string;
  stack?: string;
  statusCode: number;
};

export enum CANCELLATION_STATUS {
  CANCELLED = "CANCELLED",
  UNCANCELLED = "UNCANCELLED",
  NONE = "NONE",
}
