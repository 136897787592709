import type { FC } from "react";
import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";

import PreviewPopover from "@components/Preview/PreviewPopover";
import { InfoOutlined } from "@mui/icons-material";
import { Chip, FormLabel, Grid, IconButton, Paper } from "@mui/material";
import { type LinkedAsset } from "@src/types";

interface Props {
  linkedAssetsList?: LinkedAsset[];
}

const AssetDuplicateInfo: FC<Props> = ({ linkedAssetsList }) => {
  const popoverAnchor = useRef(null);
  const navigate = useNavigate();
  const [open, setOpenedPopover] = useState(false);

  const onClickNavigate = (productId?: number) => {
    // todo get the good productId
    if (productId) {
      const redirectWithParams = generatePath("/product/:productId", {
        productId: productId.toString(),
      });
      navigate(redirectWithParams);
    }
  };

  const handleMouseEnter = async () => {
    setOpenedPopover(true);
  };

  const handleMouseLeave = () => {
    setOpenedPopover(false);
  };

  if (!linkedAssetsList?.length) {
    return null;
  }

  return (
    <>
      <IconButton
        ref={popoverAnchor}
        size="small"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <InfoOutlined />
      </IconButton>
      <PreviewPopover
        isOpen={open}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        anchorRef={popoverAnchor}
        keepOpenOnContent
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Paper>
          <Grid
            container
            item
            p={2}
            display="flex"
            flexDirection="column"
            gap={2}
            minWidth={200}
            justifyContent="space-between"
          >
            <Grid item display="flex" gap={2} justifyContent="space-between">
              <FormLabel>
                <FormattedMessage id="asset.duplicated-on" />
              </FormLabel>
              <Grid
                item
                gap={1}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                {linkedAssetsList.map((linkedAsset) => (
                  <Chip
                    size="small"
                    clickable
                    label={linkedAsset.product.name.toUpperCase()}
                    key={linkedAsset.id}
                    onClick={() => {
                      onClickNavigate(linkedAsset.product.id);
                    }}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </PreviewPopover>
    </>
  );
};

export default AssetDuplicateInfo;
