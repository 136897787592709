import { API_TAGS } from "@src/types";
import { defaultApi } from "@store/api/api";
import endpoints from "@store/api/endpoints";

import { type User } from "types/api/user";

export const userApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<User[], void>({
      query: () => ({
        url: endpoints.user.all,
      }),
      keepUnusedDataFor: 600,
      providesTags: [API_TAGS.PRODUCTS],
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetUsersQuery } = userApi;
