import React from "react";
import {
  type Control,
  Controller,
  type FieldValues,
  type Path,
  type PathValue,
  type UseFormSetValue,
  type UseFormTrigger,
  type UseFormWatch,
} from "react-hook-form";
import { useIntl } from "react-intl";

import { CloseOutlined } from "@mui/icons-material";
import { Autocomplete, Chip, TextField } from "@mui/material";

export type MultiSelectInputOptions =
  | string[]
  | { label: string; value: string }[];

export interface IFormListInput<T extends FieldValues> {
  fieldName: Path<T>;
  fieldLabel: string;
  control: Control<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
  trigger: UseFormTrigger<T>;
  mandatory?: boolean;
  options: MultiSelectInputOptions;
  disabled?: boolean;
}

const MultiSelectInput = <T extends FieldValues>({
  fieldName,
  fieldLabel,
  control,
  watch,
  setValue,
  trigger,
  mandatory = false,
  options = [],
  disabled,
}: IFormListInput<T>) => {
  const intl = useIntl();
  const watchedField = (watch(fieldName) || []) as string[];

  const isOptionsObjects = options.length > 0 && typeof options[0] === "object";

  const normalizedOptions: { value: string; label: string }[] = isOptionsObjects
    ? (options as { label: string; value: string }[])
    : (options as string[]).map((option) => ({ value: option, label: option }));

  const handleChange = (
    _: React.SyntheticEvent,
    selectedOptions: { value: string; label: string }[],
  ) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setValue(fieldName, selectedValues as PathValue<T, Path<T>>, {
      shouldDirty: true,
    });
    trigger(fieldName);
  };

  const handleDeleteChip = (chipIndex: number) => {
    const newWatchedField = watchedField.filter(
      (_, index) => index !== chipIndex,
    );
    setValue(fieldName, newWatchedField as PathValue<T, Path<T>>, {
      shouldDirty: true,
    });
    trigger(fieldName);
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        validate: (value) => {
          return (
            !mandatory ||
            value?.length > 0 ||
            intl.formatMessage(
              { id: "common.rules.required" },
              { fieldName: fieldLabel },
            )
          );
        },
      }}
      render={({ fieldState: { error } }) => (
        <Autocomplete
          multiple
          options={normalizedOptions}
          getOptionLabel={(option) => option.label}
          value={normalizedOptions.filter((option) =>
            watchedField.includes(option.value),
          )}
          onChange={handleChange}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <Chip
                  key={key}
                  label={option.label}
                  variant="outlined"
                  {...tagProps}
                  deleteIcon={<CloseOutlined />}
                  disabled={disabled}
                  onDelete={() => handleDeleteChip(index)}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${intl.formatMessage({ id: `form.label.${fieldName}` })}${mandatory ? " *" : ""}`}
              error={!!error?.message}
              helperText={error?.message}
            />
          )}
          sx={{ width: "100%" }}
          filterSelectedOptions
          disabled={disabled}
        />
      )}
    />
  );
};

export default MultiSelectInput;
