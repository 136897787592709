import React, { type FC } from "react";
import type { FieldValues } from "react-hook-form";
import { useIntl } from "react-intl";

import ButtonGrouped from "@components/ButtonGrouped/ButtonGrouped";
import GenericProductModal from "@components/Modals/GenericProductModal";
import ModalLayout from "@components/Modals/ModalLayout";
import usePopupHandler from "@src/hooks/usePopUpHandler";
import { Authority, MODAL_ACTION_TYPES } from "@src/types";
import { selectUserHasAuthority } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";
import {
  selectAreWeBeforeNoDefDate,
  selectIsSeasonOpen,
} from "@store/season/season.selector";
import { ItemType } from "@utils/data/enums/generals.enum";

interface Props {
  id: number;
  productId?: number;
  actions: {
    action: MODAL_ACTION_TYPES;
    defaultValues?: FieldValues;
  }[];
  isAsset?: boolean;
  disabled?: boolean;
}

const ProductActions: FC<Props> = ({
  id,
  actions,
  isAsset,
  disabled,
  productId,
}) => {
  const intl = useIntl();
  const hasUpdateProductAuthority = useAppSelector((state) =>
    selectUserHasAuthority(state, Authority.UPDATE_PRODUCT_BRIEF),
  );
  const isSeasonOpen = useAppSelector(selectIsSeasonOpen);
  const isProductOrAssetDeletable = useAppSelector(selectAreWeBeforeNoDefDate);
  const {
    isPopupOpen,
    actionType,
    handleOpenPopup,
    handleClosePopup,
    defaultValues,
  } = usePopupHandler();

  return (
    <>
      {hasUpdateProductAuthority && (
        <ButtonGrouped
          disabled={disabled || !isSeasonOpen}
          options={actions.map((action) => ({
            label: intl.formatMessage(
              {
                id: `common.modal.generic-modal.actions.${action.action}`,
              },
              { itemType: isAsset ? ItemType.ASSET : ItemType.PRODUCT },
            ),
            onClick: () => handleOpenPopup(action.action, action.defaultValues),
            // can't delete product or asset after non def deadline
            disabled:
              [
                MODAL_ACTION_TYPES.DELETE_PRODUCT,
                MODAL_ACTION_TYPES.DELETE_ASSET,
              ].includes(action.action) && !isProductOrAssetDeletable,
            tooltipMessage: intl.formatMessage(
              { id: "common.button.tooltip.notDeletable" },
              { actionType: action.action },
            ),
          }))}
        />
      )}

      <ModalLayout
        open={isPopupOpen}
        onClose={handleClosePopup}
        title={intl.formatMessage(
          {
            id: `common.modal.generic-modal.title.${actionType}`,
          },
          { itemType: isAsset ? ItemType.ASSET : ItemType.PRODUCT },
        )}
      >
        <GenericProductModal
          id={id}
          productId={productId}
          actionType={actionType}
          onClose={handleClosePopup}
          isAsset={isAsset}
          defaultValues={defaultValues}
        />
      </ModalLayout>
    </>
  );
};

export default ProductActions;
