import React, { type FC, useMemo } from "react";
import type {
  Control,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { useIntl } from "react-intl";

import GenericFormField from "@components/Inputs/GenericFormField";
import { FormLabel, Grid, Typography } from "@mui/material";
import type {
  MultiSelectInputOptions,
  ProductGetResponse,
  ProductPutForm,
} from "@src/types";
import { FIELD_TYPES, OwnerRoles, type ProductFieldsConfig } from "@src/types";
import { useAppSelector } from "@store/hooks";
import { selectUsers } from "@store/user/user.selector";
import { getUsersByRoles } from "@utils/user.utils";

type CommonProps = {
  product: ProductGetResponse;
  productFieldsConfig: ProductFieldsConfig;
  canUpdateProduct?: boolean;
  setValue: UseFormSetValue<ProductPutForm>;
  trigger: UseFormTrigger<ProductPutForm>;
  control: Control<ProductPutForm>;
  watch: UseFormWatch<ProductPutForm>;
  register: UseFormRegister<ProductPutForm>;
  isDisabled: (fieldName: keyof ProductPutForm) => boolean;
};

const OwnerField: FC<
  CommonProps & {
    fieldName: keyof ProductPutForm;
    product: ProductGetResponse;
    userOptions: MultiSelectInputOptions;
  }
> = ({
  fieldName,
  product,
  register,
  watch,
  control,
  trigger,
  setValue,
  productFieldsConfig,
  isDisabled,
  userOptions,
}) => {
  const intl = useIntl();
  const label = intl.formatMessage({
    id: `form.label.${fieldName}`,
    defaultMessage: fieldName,
  });

  const userIds = product[fieldName] as string[] | undefined;
  const userWithNames = useMemo(() => {
    return userIds
      ?.map((userId) => {
        const user = (userOptions as { label: string; value: string }[]).find(
          (option) => option.value === userId,
        );
        return user ? user.label : null;
      })
      .filter(Boolean)
      .join(", ");
  }, [userIds, userOptions]);

  return (
    <Grid item display="flex" flex={1}>
      {!isDisabled(fieldName) ? (
        <GenericFormField<ProductPutForm>
          field={{
            fieldName,
            fieldType: FIELD_TYPES.MULTI_SELECT,
            mandatory: productFieldsConfig[fieldName]?.mandatory,
            muiVariant: "standard",
            size: "small",
            options: userOptions as MultiSelectInputOptions,
            noStatus: true,
          }}
          setValue={setValue}
          trigger={trigger}
          register={register}
          control={control}
          watch={watch}
        />
      ) : (
        <Grid item display="flex" flexDirection="column">
          <FormLabel>{label}</FormLabel>
          <Typography>{fieldName in product ? userWithNames : ""}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

const OwnersTab: FC<CommonProps> = (props) => {
  const users = useAppSelector(selectUsers);

  return (
    <Grid container display="flex" flexDirection="row" gap={2}>
      {Object.values(OwnerRoles).map((fieldName) => (
        <OwnerField
          {...props}
          key={fieldName}
          fieldName={fieldName}
          userOptions={getUsersByRoles(users, fieldName)}
        />
      ))}
    </Grid>
  );
};

export default OwnersTab;
