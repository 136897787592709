import React, { type FC, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import GenericFormField from "@components/Inputs/GenericFormField";
import { DeleteOutlined } from "@mui/icons-material";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import type {
  AssetDuplicate,
  AssetDuplicateRequest,
  AssetDuplicationForm,
  ProductGroupedByLevel,
} from "@src/types";
import { FIELD_TYPES } from "@src/types";
import { useDuplicateAssetMutation } from "@store/api/asset";
import { useLazyGetProductsWithSeasonQuery } from "@store/api/product";
import { useAppSelector } from "@store/hooks";
import { selectSeasons, selectedSeason } from "@store/season/season.selector";

interface Props {
  onClose: () => void;
  id: number;
  productId?: number;
}

const getProductFromSeason = (data?: ProductGroupedByLevel[]) => ({
  data:
    data?.flatMap((level) =>
      level.categories.flatMap((category) =>
        category.products
          .filter((product) => !product.isCancel)
          .map((product) => ({
            label: product.name,
            value: product.id.toString(),
          })),
      ),
    ) || [],
});

const DuplicateAssetModal: FC<Props> = ({ onClose, id, productId }) => {
  const [productOptions, setProductOptions] = useState<Record<number, any[]>>(
    {},
  );
  const [duplicateAsset, { isLoading, isSuccess }] =
    useDuplicateAssetMutation();
  const [getProducts] = useLazyGetProductsWithSeasonQuery();

  const currentSeason = useAppSelector(selectedSeason);
  const seasons = useAppSelector(selectSeasons);

  const {
    control,
    watch,
    register,
    setValue,
    trigger,
    handleSubmit,
    formState: { isValid },
  } = useForm<AssetDuplicationForm>({
    mode: "onBlur",
    defaultValues: {
      id,
      destinations: [
        {
          season: "",
          product: "",
          linked: true,
        },
      ],
    },
  });

  // we can only duplicate an asset in the seasonal brief of the same year
  const seasonsOptions = seasons
    .filter((season) => season.year === currentSeason?.year)
    .flatMap((year) => year.seasons.flatMap((season) => season.elements));

  const { fields, append, remove } = useFieldArray({
    control,
    name: "destinations",
  });

  const destinations = watch("destinations");
  useEffect(() => {
    destinations.forEach((destination, index) => {
      if (parseInt(destination.product) === productId) {
        setValue(`destinations.${index}.linked`, false);
      }
    });
  }, [destinations]);

  useEffect(() => {
    async function fetchProducts(index: number, seasonId: string) {
      if (seasonId) {
        const season = seasonsOptions.find((s) => s.id === parseInt(seasonId));
        if (season) {
          const result = await getProducts({
            cleanName: season.cleanName,
          }).unwrap();
          setProductOptions((prev) => ({
            ...prev,
            [index]: getProductFromSeason(result).data,
          }));
        }
      } else {
        setProductOptions((prev) => ({
          ...prev,
          [index]: [],
        }));
      }
    }

    destinations.forEach(async (destination, index) => {
      await fetchProducts(index, destination.season);
    });
  }, [destinations.map((d) => d.season).join(",")]);

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const onSubmit = async (data: AssetDuplicationForm) => {
    await trigger();
    if (isValid) {
      const assets: AssetDuplicate[] = data.destinations
        .filter((destination) => destination.product !== "")
        .map(
          (destination) =>
            ({
              productId: parseInt(destination.product),
              linked: destination.linked,
            }) as AssetDuplicate,
        );
      duplicateAsset({ id, assets } as AssetDuplicateRequest);
    }
  };

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Grid container gap={2} alignItems="center">
        {fields.map((field, index) => (
          <Grid container spacing={2} key={field.id} alignItems="center">
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                height: 40, // fix height when there is iconButton or not
              }}
            >
              <Typography>
                <FormattedMessage
                  id="asset.modal.duplicate.product-item"
                  values={{ index: index + 1 }}
                />
              </Typography>
              {index > 0 && (
                <IconButton onClick={() => remove(index)}>
                  <DeleteOutlined />
                </IconButton>
              )}
            </Grid>

            <Grid item xs={12}>
              <GenericFormField
                field={{
                  fieldName: `destinations.${index}.season`,
                  fieldLabel: "asset.modal.duplicate.season",
                  fieldType: FIELD_TYPES.SELECT_SEARCH,
                  mandatory: index === 0,
                  options: seasonsOptions.map((elt) => ({
                    label: elt.name,
                    value: elt.id,
                  })),
                }}
                setValue={setValue}
                register={register}
                trigger={trigger}
                control={control}
                watch={watch}
              />
            </Grid>

            <Grid item xs={12}>
              <GenericFormField
                field={{
                  fieldName: `destinations.${index}.product`,
                  fieldLabel: "asset.modal.duplicate.product",
                  fieldType: FIELD_TYPES.SELECT_SEARCH,
                  mandatory: index === 0,
                  options: productOptions[index] || [],
                }}
                setValue={setValue}
                register={register}
                control={control}
                watch={watch}
                trigger={trigger}
              />
            </Grid>
            <Grid item xs={12}>
              <GenericFormField
                field={{
                  id: destinations[index]?.product, // to update the state of checkbox
                  fieldName: `destinations.${index}.linked`,
                  fieldLabel: "asset.modal.duplicate.link-assets",
                  fieldType: FIELD_TYPES.BOOLEAN,
                  checked:
                    destinations[index].product !== "" &&
                    parseInt(destinations[index]?.product) === productId
                      ? false
                      : destinations[index].linked,
                  defaultChecked: true,
                  disabled:
                    parseInt(destinations[index]?.product) === productId,
                }}
                setValue={setValue}
                register={register}
                control={control}
                watch={watch}
                trigger={trigger}
              />
            </Grid>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => append({ season: "", product: "", linked: true })}
          >
            <FormattedMessage id="asset.modal.duplicate.add-destination" />
          </Button>
        </Grid>
      </Grid>

      <Grid container my={2}>
        <Grid item display="flex" justifyContent="space-between" width="100%">
          <Button onClick={onClose} variant="outlined">
            <FormattedMessage id="common.back" />
          </Button>

          <Button
            disabled={isLoading || !isValid}
            type="submit"
            variant="contained"
          >
            <FormattedMessage id="common.confirm.duplication" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default DuplicateAssetModal;
