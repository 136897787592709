import type { Pageable } from "types/api/common";

export enum UserNotificationChangeType {
  CREATED = "CREATED",
  DELETED = "DELETED",
  MODIFIED = "MODIFIED",
}

export interface Notification {
  id: number;
  author: string;
  date: Date;
  viewed: boolean;
  product: NotificationDetails;
  assets?: Record<number, NotificationDetails>;
}

interface NotificationDetails {
  name: string;
  id: number;
  changes?: NotificationChange[];
}

export interface NotificationChange {
  type: UserNotificationChangeType;
  field?: string;
  oldValues?: string[];
  newValues?: string[];
}

export interface NotificationReadRequest {
  ids?: number[];
}

export interface NotificationGetRequest {
  pageNumber: number;
  pageSize: number;
}

export type NotificationGetResponse = Pageable<undefined, Notification[]> & {
  nbNotViewed: number;
};

export type NotificationMode = "asset" | "product";
