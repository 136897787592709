import React from "react";
import { FormattedMessage } from "react-intl";

import { Box, Typography } from "@mui/material";
import type { User } from "@src/types";
import { CANCELLATION_STATUS, OwnerRoles } from "@src/types";
import { useAppSelector } from "@store/hooks";
import { selectUsers } from "@store/user/user.selector";
import { toCamelCase } from "@utils/common.utils";
import { getCancellationStatus } from "@utils/notification.utils";

import NotificationLine, {
  NotificationLineWithCircle,
} from "./NotificationLine";
import type { NotificationChange } from "types/api/notification";
import { UserNotificationChangeType } from "types/api/notification";

const getUserById = (users: User[], values?: string[]) =>
  values?.map((userId) => {
    const user = users.find((user) => user.id === userId);
    return user?.firstName + " " + user?.lastName;
  }) ?? [];

const NotificationChangeProduct = ({
  name,
  productId,
  changes,
  onClose,
  viewed,
}: {
  name: string;
  productId: number;
  changes?: NotificationChange[];
  onClose: () => void;
  viewed: boolean;
}) => {
  const users = useAppSelector(selectUsers);
  const updateValuesIfUser = (change: NotificationChange) => {
    const field = toCamelCase(change?.field) as OwnerRoles;
    if (field && Object.values(OwnerRoles).includes(field)) {
      return {
        ...change,
        oldValues: getUserById(users, change?.oldValues),
        newValues: getUserById(users, change?.newValues),
      };
    }
    return change;
  };

  const isNewProduct =
    !changes?.[0].field &&
    changes?.[0].type === UserNotificationChangeType.CREATED;

  const cancellationStatus = getCancellationStatus(changes, "is_cancel");

  const getProductChangeType = () => {
    if (isNewProduct) {
      return UserNotificationChangeType.CREATED;
    } else if (
      cancellationStatus === CANCELLATION_STATUS.CANCELLED ||
      (changes?.[0].field === "why_is_cancel" &&
        changes?.[0].type === UserNotificationChangeType.CREATED)
    ) {
      return UserNotificationChangeType.DELETED;
    } else if (cancellationStatus === CANCELLATION_STATUS.UNCANCELLED) {
      return UserNotificationChangeType.MODIFIED;
    }
    return UserNotificationChangeType.MODIFIED;
  };

  // do not display cancel / uncancel.
  // Information is already given in NotificationListItem
  if (
    isNewProduct ||
    [CANCELLATION_STATUS.CANCELLED, CANCELLATION_STATUS.UNCANCELLED].includes(
      cancellationStatus,
    )
  ) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="row" gap={1}>
      {changes && name && (
        <NotificationLineWithCircle
          type={getProductChangeType()}
          viewed={viewed}
        >
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage
              id={`form.label.${toCamelCase(changes[0].field)}`}
              values={{ product: name }}
            />
          </Typography>
        </NotificationLineWithCircle>
      )}
      {changes?.map((change, index) => (
        <NotificationLine
          key={index}
          change={updateValuesIfUser(change)}
          name={name}
          productId={productId}
          onClose={onClose}
          mode="product"
          viewed={viewed}
        />
      ))}
    </Box>
  );
};

export default NotificationChangeProduct;
