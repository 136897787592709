import type React from "react";

import type { ChipOwnProps } from "@mui/material";
import type {
  AssetField,
  AssetFileValueRequest,
  AssetValue,
  FileComponentGetDetails,
  SeasonsGlobalInformations,
  SeasonsOrderByYears,
} from "@src/types";
import {
  ACCEPT_FILES_TYPES,
  AssetContentFieldStatus,
  type AssetRequest,
  AssetTag,
  FieldTypes,
  type SelectOptionType,
} from "@src/types";
import { colors } from "@theme/theme";
import { DISPLAY_DATE_FORMAT } from "@utils/constants.utils";

import dayjs from "dayjs";
import type { Asset, AssetForm } from "types/api/asset";

export const generateYearsOptions = (nb = 10): SelectOptionType[] => {
  const currentYear = dayjs().year();
  return Array.from(Array(nb)).map((_, i) => ({
    value: (currentYear + i).toString(),
    label: (currentYear + i).toString(),
  }));
};

export function convertTimeZoneInDate(
  hourWithTimeZone: Date | undefined,
  forInput = false,
): string {
  if (hourWithTimeZone) {
    const date = dayjs(hourWithTimeZone);
    if (forInput) {
      return date.format("YYYY-MM-DD");
    }
    return date.format(DISPLAY_DATE_FORMAT);
  }

  return "";
}

export function isUpdatedWithinLastNDays(
  date: Date | string,
  numberOfDays: number,
) {
  const nbOfDaysAgo = dayjs().subtract(numberOfDays, "day");
  return dayjs(date).isAfter(nbOfDaysAgo);
}

export const cleanString = (s: string) =>
  s
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+/g, "")
    .replace(/((?=(-+))\2)$/g, "");

export const getZoomCompensation = () =>
  (1 / Math.round(window.devicePixelRatio * 100)) * 100;

export const getIdFromName = <T extends { name: string; id: number }>(
  name: string,
  array: T[],
) => array.find((elt) => elt.name === name)?.id || 0;

export function removeAlphaCharacters(value: string): string {
  return value.replace(/[^\d]/g, "");
}

export enum keyboardDigitInputs {
  ZERO = "0",
  ONE = "1",
  TWO = "2",
  THREE = "3",
  FOUR = "4",
  FIVE = "5",
  SIX = "6",
  SEVEN = "7",
  EIGHT = "8",
  NINE = "9",
}

export enum keyboardInputs {
  DELETE = "Delete",
  BACKSPACE = "Backspace",
  SHIFT = "Shift",
  TAB = "Tab",
  ARROW_RIGHT = "ArrowRight",
  ARROW_LEFT = "ArrowLeft",
  ENTER = "Enter",
}

export const isDigit = (value: string) => {
  // We cannot use regex because of japanese keyboard (iOS 11 and 12)
  return Object.values(keyboardDigitInputs).includes(
    value as keyboardDigitInputs,
  );
};

export const isAllowedKey = (value: string) => {
  return Object.values(keyboardInputs).includes(value as keyboardInputs);
};

export const avoidAlphaCharacters = (
  event: React.KeyboardEvent<HTMLInputElement>,
) => {
  if (!(isDigit(event.key) || isAllowedKey(event.key))) {
    event.preventDefault();
  }
};

export const getOnlyValueFromAsset = (asset: Asset): AssetForm => {
  const emptyAssetValue = {
    value: "",
    status: AssetContentFieldStatus.NULL,
  };

  return {
    new_tag: { value: asset.content.new_tag.value || [] },
    comment: asset.content.comment.value || emptyAssetValue,
    nb_of_shades_to_shoot:
      asset.content.nb_of_shades_to_shoot.value || emptyAssetValue,
    nb_of_carnations: asset.content.nb_of_carnations.value || emptyAssetValue,
    total_nb_of_assets:
      asset.content.total_nb_of_assets.value || emptyAssetValue,
    skus: asset.content.skus.value || emptyAssetValue,
    name_of_shades: asset.content.name_of_shades.value || emptyAssetValue,
    bench_visuals: { value: asset.content.bench_visuals.value || [] },
    comment_from_marketing_product:
      asset.content.comment_from_marketing_product.value || emptyAssetValue,
    geographic_scope: { value: asset.content.geographic_scope.value || [] },
    ppage: { value: asset.content.ppage.value || [] },
    digital_animation: { value: asset.content.digital_animation.value || [] },
    media_regional_context:
      asset.content.media_regional_context.value || emptyAssetValue,
    media_traditional_tv_dooh_ooh: {
      value: asset.content.media_traditional_tv_dooh_ooh.value || [],
    },
    media_digital: { value: asset.content.media_digital.value || [] },
    permanent_instore: { value: asset.content.permanent_instore.value || [] },
    temporary_instore: { value: asset.content.temporary_instore.value || [] },
    print_def_asset_delivery_deadline:
      asset.content.print_def_asset_delivery_deadline.value || emptyAssetValue,
    digital_def_delivery_deadline:
      asset.content.digital_def_delivery_deadline.value || emptyAssetValue,
  };
};

export function getDirtyValues<
  DirtyFields extends Record<string, unknown>,
  Values extends Record<keyof DirtyFields, unknown>,
>(
  dirtyFields: DirtyFields,
  values: Values,
  prevValues: Values,
  useStatus: boolean,
): Partial<typeof values> {
  return Object.keys(dirtyFields).reduce((prev, key) => {
    if (!dirtyFields[key]) return prev;

    if (key === "value") {
      if (Array.isArray(values[key])) {
        return values[key] as AssetValue[];
      }

      // Get falsy values to detect a new value, but accept 0 and false as possible previous values. Should exclude empty string, null, undefined
      const isNewValue =
        !prevValues ||
        (prevValues[key] !== 0 &&
          prevValues[key] !== false &&
          !prevValues[key]);

      return {
        ...prev,
        value: values[key],
        status: useStatus
          ? isNewValue
            ? AssetContentFieldStatus.CREATED
            : AssetContentFieldStatus.MODIFIED
          : AssetContentFieldStatus.NULL,
      };
    }
    return {
      ...prev,
      [key]: getDirtyValues(
        dirtyFields[key] as DirtyFields,
        values[key] as Values,
        prevValues[key] as Values,
        useStatus,
      ),
    };
  }, {});
}

export const consolidFileArray = (
  fileList: FileList,
  useStatus: boolean,
): AssetFileValueRequest[] => {
  return Array.from(fileList).map((file) => {
    if (useStatus) {
      return {
        value: file,
        status: AssetContentFieldStatus.CREATED,
      };
    }
    return {
      value: file,
      status: AssetContentFieldStatus.NULL,
    };
  });
};

export const kebabize = (str: string): string =>
  str.replace(
    /[A-Z]+(?![a-z])|[A-Z]/g,
    ($, ofs) => (ofs ? "-" : "") + $.toLowerCase(),
  );

export const isImage = (file: File | FileComponentGetDetails) => {
  let type = "";
  if ("contentType" in file) {
    type = file.contentType;
  } else if (file instanceof File) {
    type = file.type;
  }
  return type.startsWith("image/");
};

export const getAssetFieldType = (field?: AssetField): FieldTypes | null => {
  if (!field) {
    return null;
  }
  if (field.jsonName.includes("nb_")) {
    return FieldTypes.NUMBER;
  }
  const richTextFields = ["comment", "comment_from_marketing_product"];
  if (richTextFields.includes(field.jsonName)) {
    return FieldTypes.RICH_TEXT;
  }
  return field.type;
};

export const areJsonEqual = (obj1: any, obj2: any): boolean => {
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1).sort();
  const keys2 = Object.keys(obj2).sort();

  if (
    keys1.length !== keys2.length ||
    !keys1.every((key, i) => key === keys2[i])
  ) {
    return false;
  }

  return keys1.every((key) => areJsonEqual(obj1[key], obj2[key]));
};

export const isExcel = (contentType: string) =>
  ACCEPT_FILES_TYPES.ASSORTMENT_FILE.includes(contentType);

export const AssetStatusToColorMapping: Record<
  AssetContentFieldStatus,
  ChipOwnProps["color"]
> = {
  [AssetContentFieldStatus.CREATED]: "success",
  [AssetContentFieldStatus.DELETED]: "error",
  [AssetContentFieldStatus.MODIFIED]: "warning",
  [AssetContentFieldStatus.NULL]: "default",
};

export const getFieldColorMapping = (
  status: AssetContentFieldStatus,
  type: "default" | "hover" | "focus",
): string => {
  switch (status) {
    // todo handle others AssetContentFieldStatus if needed
    case AssetContentFieldStatus.MODIFIED:
      switch (type) {
        case "default":
          return colors.warning;
        case "hover":
          return colors.warningHovered;
        case "focus":
          return colors.warningFocused;
        default:
          return "default";
      }
    case AssetContentFieldStatus.CREATED:
      switch (type) {
        case "default":
          return colors.success;
        case "hover":
          return colors.successHovered;
        case "focus":
          return colors.successFocused;
        default:
          return "default";
      }
    case AssetContentFieldStatus.NULL:
      return "default";
    default:
      throw new Error(`Unhandled AssetContentFieldStatus: ${status}`);
  }
};

export const AssetTagToColorMapping: Record<AssetTag, string> = {
  [AssetTag.ASSET_ALREADY_REQUESTED_ON_ANOTHER_PRODUCT]: colors.yellow,
  [AssetTag.EXTEND_RIGHTS]: colors.gray,
  [AssetTag.TOP_PRIORITY_ASSET]: colors.priority,
  [AssetTag.NEW_ASSET_ADDED]: colors.yellow,
};

export function findSeasonById(
  seasonsByYears: SeasonsOrderByYears[],
  id?: number,
): SeasonsGlobalInformations | undefined {
  let rightSeason = undefined;
  seasonsByYears.forEach((seasonsByYear) => {
    seasonsByYear.seasons.forEach((seasonConsolidated) => {
      seasonConsolidated.elements.forEach((season) => {
        if (season.id === id) {
          rightSeason = season;
        }
      });
    });
  });
  return rightSeason;
}

export const downloadFileFromUrl = (fileUrl: string): void => {
  window.open(fileUrl, "_blank");
};

export const filterNonNumberInputs = (
  event: React.KeyboardEvent<HTMLDivElement>,
) => {
  if (["e", "E", "-", "+"].includes(event.key)) {
    event.preventDefault();
  }
};

export const filterNonEmptyAssetContent = (
  content: AssetRequest["content"],
): Partial<AssetRequest["content"]> => {
  const cleanedContent: AssetRequest["content"] = { ...content };

  for (const key in cleanedContent) {
    const value = cleanedContent[key as keyof AssetRequest["content"]];

    // filter empty lists
    if (Array.isArray(value)) {
      if (value.length === 0) {
        delete cleanedContent[key as keyof AssetRequest["content"]];
      }
    } // filter empty objects, empty values inside objects
    else if (typeof value === "object") {
      if (
        value !== null &&
        (Object.keys(value).length === 0 ||
          (value as AssetValue)?.value === "" ||
          (value as AssetValue)?.value === undefined)
      ) {
        delete cleanedContent[key as keyof AssetRequest["content"]];
      }
    }
  }

  return cleanedContent;
};

// Set hour to 12 to avoid timezone issue
export const normalizeTime = (date: dayjs.Dayjs | null): dayjs.Dayjs | null => {
  return date ? date.set("hour", 12) : null;
};
