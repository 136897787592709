export interface JwtAccessToken {
  ver: number;
  jti: string;
  iss: string;
  aud: string;
  iat: number;
  exp: number;
  cid: number;
  uid: number;
  scp: string[];
  auth_time: number;
  xapikey: string;
  sub: string;
  briefassetgroups: string[];
  api_groups: string[];
}

export enum Role {
  ADMINS = "ADMINS",
  IMAGE = "IMAGE",
  MARKETINGPRODUCT = "MARKETINGPRODUCT",
  DIGITAL = "DIGITAL",
  MOP = "MOP",
  TRAINING = "TRAINING",
}

export enum Authority {
  CREATE_SEASONAL_BRIEF = "CREATE_SEASONAL_BRIEF",
  UPDATE_SEASONAL_BRIEF_ANNEXES = "UPDATE_SEASONAL_BRIEF_ANNEXES",
  UPDATE_SEASONAL_BRIEF_DATES = "UPDATE_SEASONAL_BRIEF_DATES",
  OPEN_OR_CLOSE_SEASONAL_BRIEF = "OPEN_OR_CLOSE_SEASONAL_BRIEF",
  OPEN_OR_CLOSE_SEASONAL_BRIEF_IMAGE_TEAM = "OPEN_OR_CLOSE_SEASONAL_BRIEF_IMAGE_TEAM",
  CREATE_PRODUCT_BRIEF = "CREATE_PRODUCT_BRIEF",
  UPDATE_PRODUCT_BRIEF = "UPDATE_PRODUCT_BRIEF",
  CREATE_ASSET = "CREATE_ASSET",
  UPDATE_ASSET = "UPDATE_ASSET",
  UPDATE_ASSET_IMAGE_TEAM = "UPDATE_ASSET_IMAGE_TEAM",
  DOWNLOAD_BRIEF_ASSET = "DOWNLOAD_BRIEF_ASSET",
  UPDATE_PRODUCT_MARKETING_PRODUCT_TEAM = "UPDATE_PRODUCT_MARKETING_PRODUCT_TEAM",
}

export const RolesWithAuthorities: Record<Role, Authority[]> = {
  [Role.MOP]: [
    Authority.CREATE_SEASONAL_BRIEF,
    Authority.UPDATE_SEASONAL_BRIEF_ANNEXES,
    Authority.CREATE_PRODUCT_BRIEF,
    Authority.UPDATE_PRODUCT_BRIEF,
    Authority.CREATE_ASSET,
    Authority.UPDATE_ASSET,
    Authority.DOWNLOAD_BRIEF_ASSET,
  ],
  [Role.DIGITAL]: [
    Authority.UPDATE_SEASONAL_BRIEF_ANNEXES,
    Authority.CREATE_PRODUCT_BRIEF,
    Authority.UPDATE_PRODUCT_BRIEF,
    Authority.CREATE_ASSET,
    Authority.UPDATE_ASSET,
    Authority.DOWNLOAD_BRIEF_ASSET,
  ],
  [Role.MARKETINGPRODUCT]: [
    Authority.UPDATE_PRODUCT_MARKETING_PRODUCT_TEAM,
    Authority.UPDATE_ASSET,
    Authority.DOWNLOAD_BRIEF_ASSET,
  ],
  [Role.IMAGE]: [
    // Image team can close or reopen brief only after close date (admin can close at anytime)
    Authority.OPEN_OR_CLOSE_SEASONAL_BRIEF_IMAGE_TEAM,
    Authority.UPDATE_ASSET_IMAGE_TEAM,
    Authority.DOWNLOAD_BRIEF_ASSET,
  ],
  [Role.ADMINS]: [
    Authority.CREATE_SEASONAL_BRIEF,
    Authority.UPDATE_SEASONAL_BRIEF_ANNEXES,
    Authority.UPDATE_SEASONAL_BRIEF_DATES,
    Authority.OPEN_OR_CLOSE_SEASONAL_BRIEF,
    Authority.CREATE_PRODUCT_BRIEF,
    Authority.UPDATE_PRODUCT_BRIEF,
    Authority.CREATE_ASSET,
    Authority.UPDATE_ASSET,
    Authority.UPDATE_ASSET_IMAGE_TEAM,
    Authority.DOWNLOAD_BRIEF_ASSET,
  ],
  [Role.TRAINING]: [Authority.DOWNLOAD_BRIEF_ASSET],
};
