import { createSlice } from "@reduxjs/toolkit";
import type { User } from "@src/types";
import { userApi } from "@store/api/user";

interface UserState {
  users: User[];
}

const initialState: UserState = {
  users: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getUsers.matchFulfilled,
      (state, { payload }) => {
        state.users = payload;
      },
    );
  },
});

export default userSlice.reducer;
