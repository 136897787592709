import type { FC } from "react";
import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";

import { CloudUploadOutlined, ContentPasteOutlined } from "@mui/icons-material";
import { Box, FormLabel, useTheme } from "@mui/material";
import usePaste from "@src/hooks/usePaste";
import { type ACCEPT_FILES_TYPES } from "@src/types";

import { StyledDropZone, VisuallyHiddenInput } from "./Dropzone.styled";

export interface DropzoneProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  disabled?: boolean;
  fieldLabel?: string;
  handleUploadFile: (files: File[]) => void;
  error?: boolean;
  accept?: ACCEPT_FILES_TYPES;
  limit?: number;
  hide?: boolean;
}

const Dropzone: FC<DropzoneProps> = ({
  disabled,
  handleUploadFile,
  error,
  accept,
  hide,
  fieldLabel,
  limit = 1,
  ...props
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const { handlePaste, handleMouseEnter, handleMouseLeave } = usePaste(
    fileInputRef,
    handleUploadFile,
    limit,
    disabled,
    accept,
  );

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const newFiles = Array.from(files);
    handleUploadFile(newFiles);
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { files } = event.target;
    if (files) {
      const newFiles = Array.from(files);
      handleUploadFile(newFiles);
    }
  };

  const handleUpload = () => {
    !disabled && fileInputRef.current?.click();
  };

  if (hide) return null;

  const fontColor = disabled
    ? theme.palette.action.disabled
    : theme.palette.text.secondary;

  return (
    <StyledDropZone
      isError={error}
      disabled={disabled}
      onClick={handleUpload}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <VisuallyHiddenInput
        {...props}
        capture={undefined}
        type="file"
        accept={accept}
        onChange={handleFileInputChange}
        onPaste={handlePaste}
        ref={(e) => (fileInputRef.current = e)}
        multiple={limit > 1}
      />
      <CloudUploadOutlined
        className="hoverable-text"
        sx={{
          fontSize: "48px",
          color: fontColor,
        }}
      />
      <FormLabel
        className="hoverable-text"
        sx={{
          mt: 2,
          color: fontColor,
        }}
      >
        <FormattedMessage
          id="common.dropzone.title"
          values={{ fieldName: fieldLabel?.toLowerCase() }}
        />
      </FormLabel>
      <Box
        display="flex"
        alignItems="center"
        mt={2}
        gap={1}
        justifyContent="center"
      >
        <ContentPasteOutlined
          className="hoverable-text"
          sx={{
            color: fontColor,
          }}
        />
        <FormLabel
          className="hoverable-text"
          sx={{
            color: fontColor,
          }}
        >
          <FormattedMessage id="common.hover-paste-file" />
        </FormLabel>
      </Box>
    </StyledDropZone>
  );
};

export default Dropzone;
