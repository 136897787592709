import type { CSSProperties } from "react";

import { CANCELLATION_STATUS } from "@src/types";
import { colors } from "@theme/theme";

import {
  type NotificationChange,
  UserNotificationChangeType,
} from "types/api/notification";

export const removeContent = (str?: string) => {
  if (str && str.includes("content")) {
    return str.split(".")[1];
  }
  return str;
};

export const getStyleWithType = (
  type: UserNotificationChangeType,
): CSSProperties => {
  switch (type) {
    case UserNotificationChangeType.CREATED:
      return { color: colors.success };
    case UserNotificationChangeType.DELETED:
      return {
        color: colors.error,
        textDecoration: "line-through",
      };
    case UserNotificationChangeType.MODIFIED:
      return { color: colors.warning, textDecoration: "line-through" };
    default:
      return {};
  }
};

export const getIconColorWithType = (type: UserNotificationChangeType) => {
  switch (type) {
    case UserNotificationChangeType.CREATED:
      return "success";
    case UserNotificationChangeType.DELETED:
      return "error";
    case UserNotificationChangeType.MODIFIED:
      return "warning";
    default:
      return "inherit";
  }
};

export const formatNotificationValues = (value: string) => {
  if (value === "true") return "Yes";
  if (value === "false") return "No";
  return value;
};

export const getCancellationStatus = (
  changes: NotificationChange[] | undefined,
  cancelFieldName: string,
) => {
  if (
    changes?.some(
      (change) =>
        change.field === cancelFieldName && change?.newValues?.[0] === "true",
    )
  ) {
    return CANCELLATION_STATUS.CANCELLED;
  }

  if (
    changes?.some(
      (change) =>
        change.field === cancelFieldName && change?.newValues?.[0] === "false",
    )
  ) {
    return CANCELLATION_STATUS.UNCANCELLED;
  }

  return CANCELLATION_STATUS.NONE;
};
