import React from "react";
import {
  type Control,
  Controller,
  type FieldPath,
  type FieldValues,
  type Path,
  type PathValue,
  type UseFormRegister,
  type UseFormSetValue,
  type UseFormTrigger,
  type UseFormWatch,
} from "react-hook-form";
import { useIntl } from "react-intl";

import Carousel from "@components/Carousel/Carousel";
import Dropzone from "@components/Dropzone";
import FileUploader from "@components/FileUploader/FileUploader";
import BooleanToggleButton from "@components/Inputs/BooleanToggleButton";
import MultiSelectInputNoStatus from "@components/Inputs/MultiSelectInputNoStatus";
import RichTextEditor from "@components/RichTextEditor";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  type TextFieldProps,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { MultiSelectInput } from "@src/components";
import {
  ACCEPT_FILES_TYPES,
  BOOLEAN_MODE,
  FIELD_TYPES,
  type FieldConfig,
  FieldLayout,
} from "@src/types";
import { DISPLAY_DATE_FORMAT } from "@utils/constants.utils";
import {
  filterNonNumberInputs,
  getFieldColorMapping,
  normalizeTime,
} from "@utils/fonctions.utils";

import dayjs, { type Dayjs } from "dayjs";

interface Props<T extends FieldValues> {
  field: FieldConfig<T>;
  setValue: UseFormSetValue<T>;
  trigger: UseFormTrigger<T>;
  register: UseFormRegister<T>;
  control: Control<T>;
  watch: UseFormWatch<T>;
}

const GenericFormField = <T extends FieldValues>({
  field,
  setValue,
  register,
  control,
  watch,
  trigger,
}: Props<T>) => {
  const intl = useIntl();
  const watchedValue = watch(field.fieldName);

  const handleUpdateArrayValue = (
    fieldName: FieldPath<T>,
    value: PathValue<T, Path<T>>,
  ) => {
    if (Array.isArray(watchedValue)) {
      setValue(
        fieldName,
        [...(watchedValue as Array<File>), ...value] as PathValue<T, Path<T>>,
        {
          shouldDirty: true,
        },
      );
    } else {
      setValue(fieldName, value, { shouldDirty: true });
    }
  };

  const getRequiredRule = (fieldLabel: string, mandatory?: boolean) => {
    return {
      required: mandatory
        ? intl.formatMessage(
            { id: "common.rules.required" },
            { fieldName: fieldLabel },
          )
        : false,
    };
  };

  const _renderField = (field: FieldConfig<T>) => {
    const {
      fieldName,
      mandatory,
      fieldType,
      fieldLabel: newFieldLabelKeyPath,
      ...props
    } = field;

    const fieldLabel = intl.formatMessage({
      id: newFieldLabelKeyPath ?? `form.label.${fieldName}`,
      defaultMessage: fieldName,
    });

    switch (fieldType) {
      case FIELD_TYPES.SELECT:
        return (
          <Controller
            name={fieldName}
            control={control}
            rules={getRequiredRule(fieldLabel, mandatory)}
            render={({ field: formField, fieldState: { error } }) => (
              <FormControl fullWidth error={!!error}>
                <InputLabel>
                  {fieldLabel}
                  {mandatory && " *"}
                </InputLabel>
                <Select
                  {...formField}
                  label={`${fieldLabel}${mandatory ? " *" : ""}`}
                  error={!!error}
                  onChange={formField.onChange}
                  disabled={field.options.length === 0}
                  autoFocus={field.autoFocus}
                >
                  {field.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {error?.message && (
                  <FormHelperText error>{error.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        );
      case FIELD_TYPES.SELECT_SEARCH:
        return (
          <Controller
            name={fieldName}
            control={control}
            rules={getRequiredRule(fieldLabel, mandatory)}
            render={({ field: formField, fieldState: { error } }) => (
              <FormControl fullWidth error={!!error}>
                <Autocomplete
                  options={field.options}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(event, newValue) => {
                    formField.onChange(newValue ? newValue.value : null);
                  }}
                  value={
                    field.options.find(
                      (option) => option.value === formField.value,
                    ) || null
                  }
                  autoFocus={field.autoFocus}
                  renderInput={(params) => (
                    <TextField
                      {...formField}
                      {...params}
                      label={fieldLabel}
                      error={!!error}
                      required={mandatory}
                      helperText={error?.message}
                    />
                  )}
                  disabled={field.options.length === 0}
                />
              </FormControl>
            )}
          />
        );
      case FIELD_TYPES.MULTI_SELECT: {
        const noStatus = (props as { noStatus?: boolean })?.noStatus;
        if (noStatus) {
          return (
            <MultiSelectInputNoStatus
              fieldName={fieldName}
              fieldLabel={fieldLabel}
              mandatory={mandatory}
              control={control}
              watch={watch}
              setValue={setValue}
              trigger={trigger}
              options={field.options}
              disabled={field.disabled}
            />
          );
        }
        return (
          <MultiSelectInput
            fieldName={fieldName}
            fieldLabel={fieldLabel}
            mandatory={mandatory}
            control={control}
            watch={watch}
            setValue={setValue}
            trigger={trigger}
            options={field.options}
            withStatus={field.withStatus}
            disabled={field.disabled}
          />
        );
      }
      case FIELD_TYPES.FILE:
        return (
          <Controller
            name={fieldName}
            control={control}
            rules={getRequiredRule(fieldLabel, mandatory)}
            render={({ fieldState }) => {
              const errorMessage = fieldState?.error?.message;
              const limit = field.limit || 1;
              return (
                <>
                  <FormLabel
                    sx={{ mr: 1 }}
                  >{`${fieldLabel}${mandatory ? " *" : ""}`}</FormLabel>
                  <FileUploader
                    limit={limit}
                    accept={field.accept || ACCEPT_FILES_TYPES.ASSORTMENT_FILE}
                    handleUploadFiles={async (files) => {
                      const newFiles =
                        limit === 1
                          ? files
                          : [...(field.files || []), ...files];
                      setValue(fieldName, newFiles as PathValue<T, Path<T>>, {
                        shouldDirty: true,
                      });
                      await trigger(fieldName);
                    }}
                    mandatory={mandatory}
                    {...props}
                  />
                  {errorMessage && (
                    <FormHelperText error sx={{ mt: 1 }}>
                      {errorMessage}
                    </FormHelperText>
                  )}
                </>
              );
            }}
          />
        );

      case FIELD_TYPES.DATE: {
        return (
          <Controller
            control={control}
            name={fieldName}
            rules={{
              ...getRequiredRule(fieldLabel, mandatory),
              validate: {
                dateMustBeValid: (value: Dayjs) => {
                  return (
                    dayjs(value).isValid() ||
                    intl.formatMessage({ id: "common.rules.dates.valid" })
                  );
                },
                ...(field.minDate && {
                  dateMustBeAfterMinDate: (value: Dayjs) => {
                    const translationKey =
                      field.fieldName === "deadlineDef"
                        ? "common.rules.dates.after"
                        : "common.rules.dates.future";
                    return (
                      dayjs(value)
                        .startOf("date")
                        .isSameOrAfter(dayjs(field.minDate).startOf("date")) ||
                      intl.formatMessage({ id: translationKey })
                    );
                  },
                }),
                ...(field.maxDate && {
                  dateMustBeBeforeMaxDate: (value: Dayjs) =>
                    dayjs(value)
                      .startOf("date")
                      .isSameOrBefore(dayjs(field.maxDate).startOf("date")) ||
                    intl.formatMessage(
                      { id: "common.rules.dates.before" },
                      {
                        dateMin: field.label,
                        dateMax: field.maxDate.format(DISPLAY_DATE_FORMAT),
                      },
                    ),
                }),
              },
            }}
            render={({ field: formField, fieldState: { error } }) => (
              // div necessary to have only one sub element in the parent grid
              <div>
                <DatePicker
                  sx={{ width: "100%" }}
                  {...formField}
                  {...(field.minDate && {
                    minDate: field.minDate,
                  })}
                  {...(field.maxDate && {
                    maxDate: field.maxDate,
                  })}
                  autoFocus={field.autoFocus}
                  disabled={field.disabled}
                  value={formField.value ? dayjs(formField.value) : null}
                  onChange={(date) => {
                    formField.onChange(normalizeTime(date));
                    trigger(fieldName);
                  }}
                  onClose={formField.onBlur}
                  slotProps={{
                    // to not frame field in red if Date Picker dates are in error but not form value
                    // can happen if actual > minDate while browsing an old season
                    textField: {
                      onBlur: formField.onBlur,
                      inputProps: {
                        sx: {
                          ...(field.status !== undefined && {
                            "~ fieldset": {
                              borderColor: getFieldColorMapping(
                                field.status,
                                "default",
                              ),
                            },
                          }),
                        },
                      },
                      error: !!error?.message,
                    },
                  }}
                  format={DISPLAY_DATE_FORMAT}
                  label={`${fieldLabel}${mandatory ? " *" : ""}`}
                />
                {error?.message && (
                  <FormHelperText error>{error.message}</FormHelperText>
                )}
              </div>
            )}
          />
        );
      }
      case FIELD_TYPES.CLASSIC_CAROUSEL:
        // todo migrate product header carousel here
        return <></>;
      case FIELD_TYPES.DROP_ZONE_CAROUSEL: {
        const hideDropZone =
          field.limit &&
          !!field.images?.length &&
          field.images.length >= field.limit;
        return (
          <Controller
            name={fieldName}
            control={control}
            rules={{
              ...getRequiredRule(fieldLabel, mandatory),
            }}
            render={({ fieldState }) => {
              const errorMessage = fieldState?.error?.message;

              return (
                <>
                  <FormLabel>{`${fieldLabel}${mandatory ? " *" : ""}`}</FormLabel>
                  {!hideDropZone && (
                    <Dropzone
                      accept={field.accept}
                      limit={field.limit}
                      fieldLabel={fieldLabel}
                      handleUploadFile={async (files) => {
                        handleUpdateArrayValue(
                          fieldName,
                          files as PathValue<T, Path<T>>,
                        );
                        await trigger(fieldName);
                      }}
                      error={!!errorMessage}
                      {...(field.limit &&
                        field.images && {
                          disabled: field.images.length >= field.limit,
                        })}
                    />
                  )}
                  {!!field.images?.length && (
                    <Carousel
                      nbElementsPerView={field.nbElementsPerView}
                      images={field.images}
                      handleClickDelete={async (deletedIndex) => {
                        const newArray = (
                          field.images as PathValue<T, Path<T>>
                        )?.filter(
                          (image: any, index: number) => index !== deletedIndex,
                        );
                        setValue(fieldName, newArray, {
                          shouldDirty: true,
                        });
                        await trigger(fieldName);
                      }}
                    />
                  )}
                  {errorMessage && (
                    <FormHelperText error sx={{ mt: 1 }}>
                      {errorMessage}
                    </FormHelperText>
                  )}
                </>
              );
            }}
          />
        );
      }
      case FIELD_TYPES.BOOLEAN:
        if (field?.mode === BOOLEAN_MODE.TOGGLE) {
          return (
            <Controller
              name={fieldName}
              control={control}
              rules={getRequiredRule(fieldLabel, mandatory)}
              render={({ field: formField, fieldState: { error } }) => (
                <Box>
                  <BooleanToggleButton
                    label={fieldLabel}
                    value={formField.value}
                    onChange={(newValue) => formField.onChange(newValue)}
                    disabled={field.disabled}
                  />
                  {error?.message && (
                    <FormHelperText error>{error.message}</FormHelperText>
                  )}
                </Box>
              )}
            />
          );
        }
        return (
          <FormControlLabel
            disabled={field.disabled}
            control={
              <Checkbox {...register(fieldName)} {...field} size="small" />
            }
            label={fieldLabel}
          />
        );
      case FIELD_TYPES.INPUT_NUMBER:
      case FIELD_TYPES.INPUT_TEXT:
        return (
          <Controller
            name={fieldName}
            control={control}
            rules={getRequiredRule(fieldLabel, mandatory)}
            render={({ field: formField, fieldState: { error } }) => {
              // todo: do this in a better way
              const label = [
                FieldLayout.DISSOCIATED_LABEL,
                FieldLayout.ONLY_PLACEHOLDER,
              ].includes(field?.layout as FieldLayout)
                ? undefined
                : fieldLabel;

              const fieldToRender = (
                <Box>
                  <TextField
                    {...formField}
                    autoFocus={field.autoFocus}
                    sx={{
                      ...field.sx,
                      // handle status display
                      ...(field.status !== undefined && {
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: getFieldColorMapping(
                              field.status,
                              "default",
                            ),
                          },
                          ...(!field.disabled && {
                            "&:hover fieldset": {
                              borderColor: getFieldColorMapping(
                                field.status,
                                "hover",
                              ),
                            },
                          }),
                          "&.Mui-focused fieldset": {
                            borderColor: getFieldColorMapping(
                              field.status,
                              "focus",
                            ),
                          },
                        },
                      }),
                    }}
                    size={field.size}
                    fullWidth
                    variant={field.muiVariant}
                    type={
                      field.fieldType === FIELD_TYPES.INPUT_TEXT
                        ? "text"
                        : "number"
                    }
                    required={
                      field.layout !== FieldLayout.DISSOCIATED_LABEL &&
                      mandatory
                    }
                    label={label}
                    placeholder={
                      field.layout === FieldLayout.ONLY_PLACEHOLDER
                        ? fieldLabel
                        : undefined
                    }
                    InputLabelProps={{
                      shrink: !!formField.value || formField.value === 0,
                    }}
                    {...(props as TextFieldProps)}
                    onChange={(event) => {
                      if (field.onChange) {
                        field.onChange(event);
                      }
                      formField.onChange(event);
                    }}
                    // prevent negative number inputs
                    inputProps={{
                      ...field.inputProps,
                      min: 0,
                      required: undefined, // native behavior from the navigator
                    }}
                    onKeyDown={(event) => {
                      if (field.fieldType === FIELD_TYPES.INPUT_NUMBER) {
                        filterNonNumberInputs(event);
                      }
                      if (event.key === "Enter") {
                        // Unfocus the input on Enter key press
                        event.preventDefault();
                        (event.target as HTMLInputElement).blur();
                      }
                    }}
                    error={!!error}
                  />
                  {error?.message && (
                    <FormHelperText error>{error.message}</FormHelperText>
                  )}
                </Box>
              );
              if (field.layout === FieldLayout.DISSOCIATED_LABEL) {
                return (
                  <>
                    <FormLabel>{`${fieldLabel}${mandatory ? " *" : ""}`}</FormLabel>
                    <Box display="flex" justifyContent="flex-end">
                      {field.disabled ? (
                        <Typography>{formField.value}</Typography>
                      ) : (
                        fieldToRender
                      )}
                    </Box>
                  </>
                );
              }
              return fieldToRender;
            }}
          />
        );

      case FIELD_TYPES.RICH_TEXT:
        return (
          <Controller
            control={control}
            {...register(fieldName)}
            rules={getRequiredRule(fieldLabel, mandatory)}
            render={({ field: formField, fieldState: { error } }) => (
              <RichTextEditor
                {...formField}
                label={`${fieldLabel}${mandatory ? " *" : ""}`}
                value={formField.value}
                onChange={(event) => {
                  formField.onChange(event);
                }}
                maxHeight={field?.maxHeight}
                error={error?.message}
                status={field.status}
                disabled={field.disabled}
              />
            )}
          />
        );
      default:
        return <></>;
    }
  };

  return _renderField(field);
};

export default GenericFormField;
