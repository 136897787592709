import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import MenuItem from "@components/NavigationBar/components/MenuItem";
import NavCatCharters from "@components/NavigationBar/components/NavCatCharters";
import NavCatSeasonalBrief from "@components/NavigationBar/components/NavCatSeasonalBrief";
import NotificationButton from "@components/Notification/NotificationButton";
import { LogoutOutlined } from "@mui/icons-material";
import { Box, Drawer, List, useTheme } from "@mui/material";
import LogoSephoraCollection from "@resources/images/LogoSephoraCollection";
import { oktaAuth } from "@utils/config";

const Menu: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const theme = useTheme();

  return (
    <Drawer
      variant="permanent"
      sx={{
        display: "block",
        "& .MuiDrawer-paper": { boxSizing: "border-box", width: 250 },
        backgroundColor: "blue",
      }}
      open
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.primary.main,
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mt: 1, mr: 1 }}>
        <Box
          onClick={() => navigate("/")}
          sx={{ cursor: "pointer" }}
          width="75%"
          margin="auto"
        >
          <LogoSephoraCollection color={theme.palette.primary.contrastText} />
        </Box>
        <NotificationButton />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        height="100%"
      >
        <List>
          <NavCatSeasonalBrief />
          <NavCatCharters />
        </List>
        <MenuItem
          icon={<LogoutOutlined color="secondary" />}
          label={intl.formatMessage({ id: "common.logout" })}
          onClick={async () => {
            await oktaAuth.closeSession();
            await oktaAuth.signOut();
          }}
        />
      </Box>
    </Drawer>
  );
};

export default Menu;
