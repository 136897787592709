import React from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";

import NotificationChangeItem from "@components/Notification/components/NotificationChangeItem";
import { Box, ListItem, Typography } from "@mui/material";
import { CANCELLATION_STATUS } from "@src/types";
import { getCancellationStatus } from "@utils/notification.utils";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import type { Notification } from "types/api/notification";
import { UserNotificationChangeType } from "types/api/notification";

dayjs.extend(relativeTime);

const getIsNewProduct = (notification: Notification) => {
  const changes = notification.product.changes;
  const isAssetChanges =
    notification.assets && Object.values(notification.assets).length > 0;
  if (!changes?.[0] && !isAssetChanges) return true;
  return (
    !changes?.[0].field &&
    changes?.[0].type === UserNotificationChangeType.CREATED
  );
};

const NotificationListItem = ({
  notification,
  onClose,
  viewed,
}: {
  notification: Notification;
  onClose: () => void;
  viewed: boolean;
}) => {
  const navigation = useNavigate();
  const handleClickItem = (id: number) => {
    const redirectWithParams = generatePath("product/:id", {
      id: id.toString(),
    });
    navigation(redirectWithParams);
    onClose();
  };

  const isNewProduct = getIsNewProduct(notification);
  const cancellationStatus = getCancellationStatus(
    notification.product.changes,
    "is_cancel",
  );

  const getProductLabel = () => {
    if (isNewProduct) {
      return "created";
    }
    if (cancellationStatus === CANCELLATION_STATUS.CANCELLED) {
      return "canceled";
    }
    if (cancellationStatus === CANCELLATION_STATUS.UNCANCELLED) {
      return "uncanceled";
    }
    return "updated";
  };

  return (
    <ListItem divider>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography component="div">
          <Box fontWeight="600" display="inline" sx={{ color: "primary.main" }}>
            {notification.author}{" "}
            <FormattedMessage
              id={`common.notification.product-${getProductLabel()}`}
            />
          </Box>{" "}
          <Box
            fontWeight="fontWeightBold"
            display="inline"
            sx={{
              textDecoration:
                cancellationStatus === CANCELLATION_STATUS.CANCELLED
                  ? "line-through"
                  : "underline",
              color: "primary.main",
              cursor: "pointer",
            }}
            onClick={() => handleClickItem(notification.product.id)}
          >
            {notification.product.name}
          </Box>{" "}
          <Typography component="span" color="textSecondary">
            {dayjs(notification.date).fromNow()}
          </Typography>
        </Typography>

        {!isNewProduct &&
          cancellationStatus !== CANCELLATION_STATUS.CANCELLED &&
          notification.product.changes?.[0].field && ( // don't show general infos if there is no field in the changes
            <Typography variant="body2">
              <FormattedMessage id="common.notification.general-information" />
            </Typography>
          )}

        {notification.product.changes?.map((change, index) => (
          <NotificationChangeItem
            key={notification.id + (change?.field ?? "") + "-" + index}
            mode="product"
            item={{
              id: notification.product.id,
              name: notification.product.name,
              changes: [change],
            }}
            productId={notification.product.id}
            onClose={onClose}
            viewed={viewed}
          />
        ))}

        {notification.assets && (
          <>
            <Typography variant="body2">
              <FormattedMessage id="common.notification.asset-updates" />
            </Typography>
            {Object.values(notification.assets)
              ?.map(({ name, changes, id }) => ({
                name,
                changes,
                id,
              }))
              .map(({ changes, id: assetId, name: assetName }) => (
                <NotificationChangeItem
                  key={assetName + "-" + assetId}
                  mode="asset"
                  item={{
                    id: assetId,
                    name: assetName,
                    changes: changes,
                  }}
                  productId={notification.product.id}
                  onClose={onClose}
                  viewed={viewed}
                />
              ))}
          </>
        )}
      </Box>
    </ListItem>
  );
};

export default NotificationListItem;
