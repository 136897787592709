import React, { useEffect, useRef, useState } from "react";

import NotificationListItem from "@components/Notification/components/NotificationListItem";
import { CloseOutlined, NotificationsOutlined } from "@mui/icons-material";
import {
  Badge,
  Box,
  CircularProgress,
  IconButton,
  List,
  Popover,
} from "@mui/material";
import {
  useLazyGetNotificationsQuery,
  useReadNotificationsMutation,
} from "@store/api/notification";
import { selectCredentials } from "@store/auth/auth.selector";
import { useAppSelector } from "@store/hooks";

import type { Notification } from "types/api/notification";

const NotificationButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [nbNotViewed, setNbNotViewed] = useState<number>(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const credentials = useAppSelector(selectCredentials);
  const listRef = useRef<HTMLUListElement>(null);
  const open = Boolean(anchorEl);

  const [fetchNotifications, { data, isFetching }] =
    useLazyGetNotificationsQuery();
  const [readNotifications] = useReadNotificationsMutation();

  const fetch = async () => {
    if (credentials) {
      const { data } = await fetchNotifications({
        pageSize: 12,
        pageNumber,
      });
      if (data?.results) {
        setNotifications((prev) => {
          const existingIds = new Set(prev.map((notif) => notif.id));
          const newNotifications = data.results.filter(
            (notif) => !existingIds.has(notif.id),
          );
          return [...prev, ...newNotifications];
        });
      }
      if (data?.nbNotViewed) {
        setNbNotViewed(data?.nbNotViewed ?? 0);
      }
    }
  };

  // fetch notifications when mounting component
  useEffect(() => {
    fetch();
  }, [credentials, pageNumber]);

  // read all notifications if we open notification panel
  useEffect(() => {
    if (open && notifications.some((notification) => !notification.viewed)) {
      readNotifications({});
      setNbNotViewed(0);
    }
  }, [open]);

  // fetch notification every hour at xxh00 (auto refresh after notification batch execution)
  useEffect(() => {
    const now = new Date();
    const nextHour = new Date();
    nextHour.setHours(now.getHours() + 1, 0, 0, 0);

    const timeToNextHour = nextHour.getTime() - now.getTime();

    // schedule a first notification refresh for the next hour
    const timeout = setTimeout(() => {
      fetch();

      // schedule a notification every hours
      const interval = setInterval(fetch, 60 * 60 * 1000);

      return () => clearInterval(interval);
    }, timeToNextHour);

    return () => clearTimeout(timeout);
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;

      if (
        scrollTop + clientHeight >= scrollHeight - 10 &&
        !isFetching &&
        data &&
        pageNumber + 1 < data.pageable.totalPages
      ) {
        setPageNumber((prev) => prev + 1);
      }
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <Badge badgeContent={nbNotViewed} color="error">
          <NotificationsOutlined color="secondary" />
        </Badge>
      </IconButton>

      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ maxWidth: "90%" }}
      >
        <Box position="relative">
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 8, right: 8, zIndex: 2000 }}
          >
            <CloseOutlined />
          </IconButton>
          <List
            component="nav"
            ref={listRef}
            onScroll={handleScroll}
            sx={{ maxHeight: "80vh", overflowY: "auto" }}
          >
            {notifications?.map((notification) => (
              <NotificationListItem
                key={notification.id}
                notification={notification}
                onClose={handleClose}
                viewed={notification.viewed}
              />
            ))}
            {isFetching && (
              <Box sx={{ display: "flex", p: 2, justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            )}
          </List>
        </Box>
      </Popover>
    </>
  );
};

export default NotificationButton;
