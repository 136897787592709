import type { MultiSelectInputOptions, OwnerRoles, User } from "@src/types";
import { Role } from "@src/types";

const ownersFieldsMapToRole: Record<OwnerRoles, Role> = {
  mopOwner: Role.MOP,
  digitalOwner: Role.DIGITAL,
  productOwner: Role.MARKETINGPRODUCT,
  imageProductOwner: Role.IMAGE,
  imageModelShotOwner: Role.IMAGE,
};

export const getUsersByRoles = (userList: User[] = [], role: OwnerRoles) => {
  return (userList
    ?.filter((user) => user.roles.includes(ownersFieldsMapToRole[role]))
    .map((user) => ({
      label: user.firstName + " " + user.lastName,
      value: user.id,
    })) ?? []) as MultiSelectInputOptions;
};
