import type { ReactElement, ReactNode } from "react";
import React, { type FC, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { VisuallyHiddenInput } from "@components/Dropzone/Dropzone.styled";
import FileUploaderChip from "@components/FileUploader/FileUploaderChip";
import PreviewExcel from "@components/Preview/PreviewExcel";
import { ContentPasteOutlined } from "@mui/icons-material";
import { Box, Button, Stack, Tooltip } from "@mui/material";
import usePaste from "@src/hooks/usePaste";
import type { ACCEPT_FILES_TYPES, FileComponentGetDetails } from "@src/types";
import { FileUploaderPreviewMode } from "@src/types";
import { isExcel } from "@utils/fonctions.utils";

export interface FileUploaderProps {
  files?: (File | FileComponentGetDetails)[];
  limit: number;
  accept: ACCEPT_FILES_TYPES;
  disabled?: boolean;
  mandatory?: boolean;
  handleUploadFiles: (files: File[]) => void;
  handleDeleteFile?: (index: number) => void;
  hide?: boolean;
  previewMode?: FileUploaderPreviewMode;
  label?: string | ReactNode;
  highlightIfRecent?: boolean;
}

export const PasteTooltip = ({
  children,
}: {
  children: ReactElement<any, any>;
}) => {
  return (
    <Tooltip
      title={
        <Box display="flex" alignItems="center" gap={1} justifyContent="center">
          <ContentPasteOutlined
            className="hoverable-text"
            sx={{
              fontSize: 18,
              color: "secondary.main",
            }}
          />
          <FormattedMessage id="common.paste-file" />
        </Box>
      }
      placement="bottom"
    >
      {children}
    </Tooltip>
  );
};

const FileUploader: FC<FileUploaderProps> = ({
  files = [],
  disabled,
  mandatory,
  handleUploadFiles,
  handleDeleteFile,
  limit,
  accept,
  hide,
  previewMode = FileUploaderPreviewMode.POPOVER,
  label,
  highlightIfRecent = false,
}) => {
  const [isReplacing, setIsReplacing] = useState<number | undefined>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { handlePaste, handleMouseEnter, handleMouseLeave } = usePaste(
    fileInputRef,
    handleUploadFiles,
    limit,
    disabled,
    accept,
  );

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDelete = (index: number) => {
    if (handleDeleteFile) {
      handleDeleteFile(index);
    }
  };

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {
      target: { files: filesList },
    } = event;
    if (isReplacing !== undefined) {
      handleDelete(isReplacing);
      setIsReplacing(undefined);
    }

    if (filesList?.length) {
      const newFiles = Array.from(filesList);
      handleUploadFiles(newFiles);
    }
  };

  const canReplaceFile = limit === 1 && files.length === 1 && mandatory;

  return (
    <>
      <VisuallyHiddenInput
        ref={(e) => {
          fileInputRef.current = e;
        }}
        type="file"
        onChange={handleFileInputChange}
        onPaste={handlePaste}
        multiple={limit > 1}
        accept={accept}
        disabled={disabled || hide}
      />

      <Stack direction="row" useFlexGap gap={1} sx={{ flexWrap: "wrap" }}>
        {files?.map((file, index) => (
          <Box key={index}>
            <FileUploaderChip
              sx={{ mt: 0.75 }}
              file={file}
              index={index}
              disabled={disabled}
              canReplaceFile={canReplaceFile}
              onDelete={() => handleDelete(index)}
              onReplace={() => {
                setIsReplacing(index);
                fileInputRef.current?.click();
              }}
              hide={hide}
              showPopover={previewMode === FileUploaderPreviewMode.POPOVER}
              highlightIfRecent={highlightIfRecent}
              onEditMouseEnter={handleMouseEnter}
              onEditMouseLeave={handleMouseLeave}
            />
            {previewMode === FileUploaderPreviewMode.INLINE &&
              file instanceof File &&
              isExcel(file.type) && <PreviewExcel file={file} showAccordion />}
          </Box>
        ))}

        {!canReplaceFile && !hide && (
          <PasteTooltip>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClick}
              disabled={disabled || files.length >= limit}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {label ?? <FormattedMessage id="common.upload-file" />}
            </Button>
          </PasteTooltip>
        )}
      </Stack>
    </>
  );
};

export default FileUploader;
