import React from "react";
import type {
  Control,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";

import GenericFormField from "@components/Inputs/GenericFormField";
import useAssetFieldDisabled from "@src/hooks/useAssetFieldDisabled";
import {
  type AssetField,
  type BooleanAssetContent,
  type DateAssetContent,
  FIELD_TYPES,
  type FieldConfig,
  FieldTypes,
  type ListAssetContent,
  type TextAssetContent,
} from "@src/types";
import { getAssetFieldType } from "@utils/fonctions.utils";

import type { AssetForm } from "types/api/asset";

interface ContentAssetProps {
  field?: AssetField;
  register: UseFormRegister<AssetForm>;
  useStatus: boolean;
  control: Control<AssetForm>;
  watch: UseFormWatch<AssetForm>;
  setValue: UseFormSetValue<AssetForm>;
  trigger: UseFormTrigger<AssetForm>;
  disabled?: boolean;
}

const AssetFormField: React.FC<ContentAssetProps> = ({
  field,
  register,
  trigger,
  useStatus,
  control,
  watch,
  setValue,
  disabled: defaultDisabled,
}) => {
  const useIsDisabled = useAssetFieldDisabled();
  const disabled = defaultDisabled || (field && useIsDisabled(field));

  if (!field) {
    return <></>;
  }

  const setGenericFieldProps = (): FieldConfig<AssetForm> => {
    const fieldType = getAssetFieldType(field);
    switch (fieldType) {
      case FieldTypes.BOOLEAN:
        field = field as BooleanAssetContent;
        return {
          fieldName: `${field.jsonName as keyof AssetForm}.value`,
          fieldType: FIELD_TYPES.BOOLEAN,
          disabled: disabled,
          mandatory: field.mandatory,
          checked: field.value.value,
        };
      case FieldTypes.LIST:
        field = field as ListAssetContent;
        return {
          fieldName: `${field.jsonName as keyof AssetForm}.value`,
          fieldType: FIELD_TYPES.MULTI_SELECT,
          options: field.possibleValues || [],
          disabled: disabled,
          mandatory: field.mandatory,
          withStatus: useStatus,
        };
      case FieldTypes.NUMBER:
        field = field as TextAssetContent;
        return {
          fieldName: `${field.jsonName as keyof AssetForm}.value`,
          fieldType: FIELD_TYPES.INPUT_NUMBER,
          mandatory: field.mandatory,
          disabled: disabled,
          ...("placeholder" in field && {
            placeholder: field.placeholder,
          }),
          withStatus: useStatus,
          status: field.value?.status,
          placeholder: field.placeholder,
        };
      case FieldTypes.RICH_TEXT:
        field = field as TextAssetContent;
        return {
          fieldName: `${field.jsonName as keyof AssetForm}.value`,
          fieldType: FIELD_TYPES.RICH_TEXT,
          status: field.value?.status,
          mandatory: field.mandatory,
          disabled: disabled,
          ...("placeholder" in field && {
            placeholder: field.placeholder,
          }),
        };
      case FieldTypes.DATE:
        field = field as DateAssetContent;
        return {
          fieldName: `${field.jsonName as keyof AssetForm}.value`,
          fieldType: FIELD_TYPES.DATE,
          mandatory: field.mandatory,
          disabled: disabled,
          ...("placeholder" in field && {
            placeholder: field.placeholder,
          }),
          status: field.value?.status,
        };
      case FieldTypes.TEXT:
      default:
        field = field as TextAssetContent;
        return {
          fieldName: `${field.jsonName as keyof AssetForm}.value`,
          fieldType: FIELD_TYPES.INPUT_TEXT,
          mandatory: field.mandatory,
          disabled: disabled,
          ...("placeholder" in field && {
            placeholder: field.placeholder,
          }),
          withStatus: useStatus,
          status: field.value?.status,
        };
    }
  };

  return (
    <GenericFormField
      field={setGenericFieldProps()}
      register={register}
      trigger={trigger}
      control={control}
      watch={watch}
      setValue={setValue}
    />
  );
};

export default AssetFormField;
