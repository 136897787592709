import React, { useState } from "react";

import MenuItem from "@components/NavigationBar/components/MenuItem";
import { PermMediaOutlined } from "@mui/icons-material";
import { Collapse, List } from "@mui/material";
import { useLazyGetCharterQuery } from "@store/api/file";
import { downloadFileFromUrl } from "@utils/fonctions.utils";

const ChartersTitle = "Charters";

const charters = [
  {
    label: "ASSETS TYPOLOGIES",
    fileName: "ASSET CHARTER_TYPOLOGIES+CARNATIONS.pdf",
  },
  {
    label: "VM MAKE UP 25",
    fileName: "VISUAL CHARTER_MAKE-UP 25_3.pdf",
  },
  {
    label: "VM ACCESSORIES 25",
    fileName: "VISUAL CHARTER_ACCESSORIES 25_3.pdf",
  },
  {
    label: "VM ACCESSORIES 25 NAM",
    fileName: "NAM ACCESS VISUALS CHARTER FA25_2.pdf",
  },
  {
    label: "VM SKINCARE 25",
    fileName: "VISUAL CHARTER_SKINCARE 25_3.pdf",
  },
  { label: "WINDOW EU", fileName: "WINDOW EU_CHARTER_V1.pdf" },
];

function NavCatCharters() {
  const [getCharter] = useLazyGetCharterQuery();
  const [showCharters, setShowCharters] = useState(false);

  const handleCharterClick = () => {
    setShowCharters(!showCharters);
  };

  return (
    <>
      <MenuItem
        icon={<PermMediaOutlined color="secondary" />}
        label={ChartersTitle}
        onClick={() => {
          handleCharterClick();
        }}
        hasNestedRoutes
        isNestedMenuOpen={showCharters}
        isTitle={true}
      />
      <Collapse in={showCharters} timeout="auto" unmountOnExit>
        <List sx={{ ml: 2 }}>
          {charters.map((charter) => (
            <MenuItem
              key={charter.label}
              label={charter.label}
              onClick={async () => {
                const response = await getCharter({
                  fileName: charter.fileName,
                });
                if (response.data?.url) {
                  downloadFileFromUrl(response.data?.url);
                }
              }}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default NavCatCharters;
