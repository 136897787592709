import { defaultApi } from "store/api/api";
import endpoints from "store/api/endpoints";
import { API_TAGS, METHODS } from "types";
import type {
  NotificationGetRequest,
  NotificationGetResponse,
  NotificationReadRequest,
} from "types/api/notification";

export const notificationApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    getNotifications: build.query<
      NotificationGetResponse,
      NotificationGetRequest
    >({
      query: (params) => ({
        url: endpoints.notifications.all,
        params,
      }),
      keepUnusedDataFor: 600,
      providesTags: [API_TAGS.NOTIFICATIONS],
    }),
    readNotifications: build.mutation<void, NotificationReadRequest>({
      query: (notificationIds) => {
        return {
          url: endpoints.notifications.viewed,
          method: METHODS.POST,
          body: notificationIds,
        };
      },
      invalidatesTags: [API_TAGS.NOTIFICATIONS],
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetNotificationsQuery, useReadNotificationsMutation } =
  notificationApi;
