import React from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";

import { ChevronRightOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { CANCELLATION_STATUS } from "@src/types";
import {
  getCancellationStatus,
  removeContent,
} from "@utils/notification.utils";

import NotificationLine, {
  NotificationLineWithCircle,
} from "./NotificationLine";
import type { NotificationChange } from "types/api/notification";
import { UserNotificationChangeType } from "types/api/notification";

const NotificationChangeAsset = ({
  name,
  productId,
  changes,
  onClose,
  viewed,
}: {
  name: string;
  productId: number;
  changes?: NotificationChange[];
  onClose: () => void;
  viewed: boolean;
}) => {
  const navigation = useNavigate();
  const handleClickItem = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    const redirectWithParams = generatePath("product/:id", {
      id: productId.toString(),
    });
    navigation(redirectWithParams);
    onClose();
  };

  const isNewAsset =
    !changes?.[0].field &&
    changes?.[0].type === UserNotificationChangeType.CREATED;

  const cancellationStatus = getCancellationStatus(changes, "is_cancelled");

  const getAssetLabel = () => {
    if (isNewAsset) {
      return "created";
    }
    if (cancellationStatus === CANCELLATION_STATUS.CANCELLED) {
      return "canceled";
    }
    if (cancellationStatus === CANCELLATION_STATUS.UNCANCELLED) {
      return "uncanceled";
    }
    return "updated";
  };

  const getAssetChangeType = () => {
    if (isNewAsset) {
      return UserNotificationChangeType.CREATED;
    } else if (cancellationStatus === CANCELLATION_STATUS.CANCELLED) {
      return UserNotificationChangeType.DELETED;
    } else if (cancellationStatus === CANCELLATION_STATUS.UNCANCELLED) {
      return UserNotificationChangeType.MODIFIED;
    }
    return UserNotificationChangeType.MODIFIED;
  };

  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      {changes && name && (
        <NotificationLineWithCircle type={getAssetChangeType()} viewed={viewed}>
          <Typography component="div">
            <Box
              display="inline"
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={(e) => handleClickItem(e)}
            >
              {name}
            </Box>{" "}
            <Box display="inline" sx={{ color: "primary.main" }}>
              <FormattedMessage
                id={`common.notification.asset-${getAssetLabel()}`}
              />
            </Box>
          </Typography>
        </NotificationLineWithCircle>
      )}
      {!isNewAsset &&
        changes
          ?.filter((change) => change.field !== "is_cancelled")
          ?.map((change, index) => (
            <Box
              key={`${change.field || "unknown-field"}-${index}`}
              display="flex"
              alignItems="center"
              ml={5}
              gap={1}
            >
              {change.field && (
                <Box display="flex" alignItems="center" gap={1}>
                  <ChevronRightOutlined fontSize="small" color="disabled" />
                  <Typography variant="body2" color="textSecondary">
                    <FormattedMessage
                      id={`form.label.${removeContent(change.field)}.value`}
                    />
                  </Typography>
                </Box>
              )}

              <NotificationLine
                change={change}
                name={name}
                productId={productId}
                onClose={onClose}
                mode="asset"
                viewed={viewed}
              />
            </Box>
          ))}
    </Box>
  );
};

export default NotificationChangeAsset;
