import React, { useState } from "react";
import type {
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { type Control, Controller } from "react-hook-form";
import { useIntl } from "react-intl";

import { CloseOutlined } from "@mui/icons-material";
import UndoIcon from "@mui/icons-material/Undo";
import type { ChipOwnProps } from "@mui/material";
import {
  Autocomplete,
  type AutocompleteChangeReason,
  Chip,
  TextField,
} from "@mui/material";
import type { MultiSelectInputOptions } from "@src/types";
import { AssetContentFieldStatus, type AssetValue } from "@src/types";
import { AssetStatusToColorMapping } from "@utils/fonctions.utils";

export interface IFormListInput<T extends FieldValues> {
  fieldName: Path<T>;
  fieldLabel: string;
  control: Control<T>;
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
  trigger: UseFormTrigger<T>;
  mandatory?: boolean;
  options: MultiSelectInputOptions;
  disabled?: boolean;
  withStatus?: boolean;
}

const MultiSelectInput = <T extends FieldValues>({
  fieldName,
  fieldLabel,
  control,
  watch,
  setValue,
  trigger,
  mandatory = false,
  options = [],
  disabled,
  withStatus,
}: IFormListInput<T>) => {
  const intl = useIntl();
  const watchedField = (watch(fieldName) as AssetValue[]) || [];

  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editValue, setEditValue] = useState<string>("");

  const handleAddChip = (value: string) => {
    setValue(
      fieldName,
      [
        ...watchedField,
        {
          value: value,
          status: withStatus
            ? AssetContentFieldStatus.CREATED
            : AssetContentFieldStatus.NULL,
        },
      ] as PathValue<T, Path<T>>,
      {
        shouldDirty: true,
      },
    );
    trigger(fieldName);
  };

  const handleSaveEditChip = (chipIndex: number) => {
    const newWatchedField = watchedField;
    newWatchedField.splice(chipIndex, 1, {
      value: editValue,
      status: withStatus
        ? AssetContentFieldStatus.MODIFIED
        : AssetContentFieldStatus.NULL,
    });
    setValue(fieldName, newWatchedField as PathValue<T, Path<T>>, {
      shouldDirty: true,
    });
    setEditingIndex(null);
  };

  const handleEditChip = (chipIndex: number) => {
    setEditingIndex(chipIndex);
    setEditValue(watchedField[chipIndex].value);
  };

  const handleDeleteChip = (chipIndex: number) => {
    const newWatchedField = watchedField;
    if (withStatus) {
      newWatchedField.splice(chipIndex, 1, {
        value: watchedField[chipIndex].value,
        status:
          watchedField[chipIndex].status === AssetContentFieldStatus.DELETED
            ? AssetContentFieldStatus.NULL
            : AssetContentFieldStatus.DELETED,
      });
    } else {
      newWatchedField.splice(chipIndex, 1);
    }
    setValue(fieldName, newWatchedField as PathValue<T, Path<T>>, {
      shouldDirty: true,
    });
    // if user deletes a chip without focusing the select field first,
    // Parent select field will never be blured, and form validate trigger won't be called
    // force it
    trigger(fieldName);
  };

  const handleClearAllChips = () => {
    let newWatchedField = watchedField;
    if (withStatus) {
      newWatchedField = newWatchedField.map((chip) => ({
        ...chip,
        status: AssetContentFieldStatus.DELETED,
      }));
    } else {
      newWatchedField = [];
    }
    setValue(fieldName, newWatchedField as PathValue<T, Path<T>>, {
      shouldDirty: true,
    });
    // if user deletes a chip without focusing the select field first,
    // Parent select field will never be blured, and form validate trigger won't be called
    // force it
    trigger(fieldName);
  };

  const handleChange = (
    _: React.SyntheticEvent,
    value: string[],
    reason: AutocompleteChangeReason,
  ) => {
    switch (reason) {
      case "createOption":
      case "selectOption":
        handleAddChip(value[value.length - 1]);
        return;
      case "clear":
        handleClearAllChips();
        return;
    }
  };

  const getChipStatus = (value: string) =>
    watchedField.find((val) => val.value === value)?.status ||
    AssetContentFieldStatus.NULL;

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{
        validate: (value) => {
          return (
            !mandatory ||
            value?.length > 0 ||
            intl.formatMessage(
              { id: "common.rules.required" },
              { fieldName: fieldLabel },
            )
          );
        },
      }}
      render={({ fieldState: { error } }) => (
        <Autocomplete
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${intl.formatMessage({ id: `form.label.${fieldName}` })}${mandatory ? " *" : ""}`}
              error={!!error?.message}
              helperText={error?.message}
            />
          )}
          sx={{ width: "100%" }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              const status = withStatus
                ? getChipStatus(option)
                : AssetContentFieldStatus.NULL;

              let color: ChipOwnProps["color"] =
                AssetStatusToColorMapping[status];
              if (
                color === "default" &&
                fieldName === "ppage.value" &&
                !/^\d+$/.test(option)
              ) {
                color = "info";
              }

              if (editingIndex === index) {
                return (
                  <TextField
                    key={index}
                    size="small"
                    value={editValue}
                    disabled={disabled}
                    onChange={(e) => setEditValue(e.target.value)}
                    onBlur={() => {
                      handleSaveEditChip(index);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") handleSaveEditChip(index);
                    }}
                    autoFocus
                  />
                );
              }

              return (
                <Chip
                  key={key}
                  label={option}
                  variant="outlined"
                  color={color}
                  deleteIcon={
                    status === AssetContentFieldStatus.DELETED ? (
                      <UndoIcon />
                    ) : (
                      <CloseOutlined />
                    )
                  }
                  {...(status === AssetContentFieldStatus.DELETED && {
                    sx: { textDecoration: "line-through" },
                  })}
                  {...tagProps}
                  disabled={disabled}
                  onClick={() => handleEditChip(index)}
                  onDelete={() => handleDeleteChip(index)}
                />
              );
            })
          }
          onChange={handleChange}
          value={watchedField?.map((value) => value.value)}
          getOptionLabel={(option) => option}
          multiple
          freeSolo
          filterSelectedOptions
          disabled={disabled}
          options={options as string[]}
        />
      )}
    />
  );
};

export default MultiSelectInput;
