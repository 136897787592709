import {
  type Middleware,
  type MiddlewareAPI,
  isFulfilled,
  isPending,
  isRejectedWithValue,
} from "@reduxjs/toolkit";
import type { ApiError } from "@src/types";
import { removeCredentials } from "@store/auth/auth.reducer";
import {
  type SnackbarProps,
  addSnackbar,
  removeSnackbarFromMessage,
} from "@store/common/common.reducer";

const excludedSuccessSnackbarEndpointsName = [
  "putSeason",
  "putProduct",
  "putAsset",
];

const excludedSnackbarEndpointsName = ["readNotifications"];

const rtkQueryErrorLogger: Middleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    const displayError = (props: SnackbarProps) =>
      dispatch(addSnackbar({ ...props, variant: "error" }));
    const displaySuccess = (props: SnackbarProps) =>
      dispatch(addSnackbar({ ...props, variant: "success" }));

    const displayPending = (props: SnackbarProps) =>
      dispatch(
        addSnackbar({
          ...props,
          variant: "default",
          preventDuplicate: true,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }),
      );

    if (isRejectedWithValue(action)) {
      if (action.payload.status === 401) {
        dispatch(removeCredentials());
      } else if (action.payload.status !== 404) {
        (action.payload.data as ApiError)?.messages?.forEach((message) => {
          displayError({
            message,
            translation: true,
          });
        });
      }
    } else if (
      isFulfilled(action) &&
      action.meta.arg.type === "mutation" &&
      ![
        ...excludedSuccessSnackbarEndpointsName,
        ...excludedSnackbarEndpointsName,
      ].includes(action.meta.arg.endpointName)
    ) {
      //Display success snackbar when request is successful (mutation only, i.e post/put/delete)
      displaySuccess({
        message: `success.${action.meta.arg.endpointName}`,
        translation: true,
      });
    } else if (
      excludedSuccessSnackbarEndpointsName.includes(
        action.meta?.arg?.endpointName,
      )
    ) {
      const message = `pending.${action.meta.arg.endpointName}`;
      if (isPending(action)) {
        //Display pending snackbar when request is pending (on specific calls)
        displayPending({
          message,
          translation: true,
          startedTimeStamp: action.meta.startedTimeStamp,
        });
      } else {
        dispatch(removeSnackbarFromMessage(message));
      }
    }
    return next(action);
  };

export default rtkQueryErrorLogger;
