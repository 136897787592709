import React from "react";

import NotificationChangeAsset from "./NotificationChangeAsset";
import NotificationChangeProduct from "./NotificationChangeProduct";
import type { NotificationChange } from "types/api/notification";

type NotificationMode = "asset" | "product";

const NotificationChangeItem = ({
  mode,
  item,
  onClose,
  viewed,
  productId,
}: {
  mode: NotificationMode;
  item: { name: string; id: number; changes?: NotificationChange[] };
  onClose: () => void;
  viewed: boolean;
  productId: number;
}) => {
  return mode === "asset" ? (
    <NotificationChangeAsset
      name={item.name}
      productId={productId}
      changes={item.changes}
      onClose={onClose}
      viewed={viewed}
    />
  ) : (
    <NotificationChangeProduct
      name={item.name}
      productId={productId}
      changes={item.changes}
      onClose={onClose}
      viewed={viewed}
    />
  );
};

export default NotificationChangeItem;
