import type { ReactNode } from "react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";

import { ArrowForwardOutlined, Circle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import {
  formatNotificationValues,
  getIconColorWithType,
  getStyleWithType,
} from "@utils/notification.utils";

import type {
  NotificationChange,
  NotificationMode,
} from "types/api/notification";
import { UserNotificationChangeType } from "types/api/notification";

export const NotificationLineWithCircle = ({
  viewed,
  type,
  children,
}: {
  viewed: boolean;
  type: UserNotificationChangeType;
  children: ReactNode;
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={2}
      ml={!viewed ? 0 : 3}
    >
      {!viewed && (
        <Circle color={getIconColorWithType(type)} sx={{ fontSize: 8 }} />
      )}
      {children}
    </Box>
  );
};

const NotificationLine = ({
  change,
  productId,
  onClose,
  mode,
  name,
  viewed,
}: {
  change: NotificationChange;
  onClose: () => void;
  productId: number;
  mode: NotificationMode;
  name: string;
  viewed: boolean;
}) => {
  const navigation = useNavigate();

  const handleClickItem = () => {
    const redirectWithParams = generatePath("product/:id", {
      id: productId.toString(),
    });
    navigation(redirectWithParams);
    onClose();
  };

  if (change.type === UserNotificationChangeType.CREATED && !change.field)
    return (
      <NotificationLineWithCircle type={change.type} viewed={viewed}>
        <Typography variant="body2">
          <FormattedMessage id={`common.notification.${mode}-new`} />{" "}
        </Typography>
        <Typography
          onClick={handleClickItem}
          variant="body2"
          color="textSecondary"
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          {name}
        </Typography>
      </NotificationLineWithCircle>
    );

  if (change.type === UserNotificationChangeType.DELETED) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        flex={1}
        gap={1}
      >
        <Typography variant="body2" sx={getStyleWithType(change.type)}>
          {change?.oldValues?.join(", ")}
        </Typography>
      </Box>
    );
  }

  const oldValuesSet = new Set(change?.oldValues || []);
  const newValuesSet = new Set(change?.newValues || []);
  const oldValues = change?.oldValues || [];
  const newValues = change?.newValues || [];

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      flex={1}
      gap={1}
    >
      {oldValues.length > 0 && (
        <>
          <Typography variant="body2" sx={getStyleWithType(change.type)}>
            {oldValues.map((value, index) => {
              const formattedValue = formatNotificationValues(value);
              const isRemoved =
                !newValuesSet.has(value) && oldValues.length > 2; // >2 to avoid single values et show changes only in array
              return (
                <span
                  key={index}
                  style={{ fontWeight: isRemoved ? "bold" : "normal" }}
                >
                  {formattedValue}
                  {index < oldValues.length - 1 ? ", " : ""}
                </span>
              );
            })}
          </Typography>
          {newValues && newValues.length > 0 && (
            <ArrowForwardOutlined sx={{ fontSize: 16 }} />
          )}
        </>
      )}

      <Typography
        variant="body2"
        sx={getStyleWithType(UserNotificationChangeType.CREATED)}
      >
        {newValues.map((value, index) => {
          const formattedValue = formatNotificationValues(value);
          const isAdded = !oldValuesSet.has(value) && newValues.length > 2;
          return (
            <span
              key={index}
              style={{ fontWeight: isAdded ? "bold" : "normal" }}
            >
              {formattedValue}
              {index < newValues.length - 1 ? ", " : ""}
            </span>
          );
        })}
      </Typography>
    </Box>
  );
};

export default NotificationLine;
