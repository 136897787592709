import type React from "react";
import { useState } from "react";

import type { ACCEPT_FILES_TYPES } from "@src/types";

const handleFileInputPaste = (
  event: React.ClipboardEvent<HTMLInputElement>,
  onPaste: (files: File[]) => void,
  limit = 1,
  disabled?: boolean,
  accept?: ACCEPT_FILES_TYPES,
) => {
  if (disabled) {
    return;
  }

  const { files } = event.clipboardData;
  if (files && files.length > 0) {
    const newFiles = limit
      ? Array.from(files).slice(0, limit)
      : Array.from(files);

    if (accept) {
      const acceptedTypes = accept.split(",").map((type) => type.trim());

      const filteredFiles = newFiles.filter((file) => {
        return acceptedTypes.some((type) => {
          if (type.startsWith(".")) {
            // verify extension
            return file.name.toLowerCase().endsWith(type);
          } else {
            // verify mime types
            return file.type === type;
          }
        });
      });

      if (filteredFiles.length === 0) {
        console.warn("No files accepted.");
        return;
      }

      onPaste(filteredFiles);
    } else {
      onPaste(newFiles);
    }
  }
};

const usePaste = (
  inputRef: React.MutableRefObject<HTMLInputElement | null>,
  onPaste: (files: File[]) => void,
  limit = 1,
  disabled?: boolean,
  accept?: ACCEPT_FILES_TYPES,
) => {
  const [isPasting, setIsPasting] = useState(false);

  const handleMouseEnter = (event: React.MouseEvent) => {
    event.preventDefault();
    setTimeout(() => {
      inputRef.current?.focus({ preventScroll: true });
    }, 0); // prevent problem when there is onBlur before focus
  };

  const handleMouseLeave = (event: React.MouseEvent) => {
    event.preventDefault();
    inputRef.current?.blur();
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (isPasting) {
      console.warn("Please wait before pasting again");
      return;
    }
    setIsPasting(true);
    handleFileInputPaste(e, onPaste, limit, disabled, accept);
    setTimeout(() => {
      setIsPasting(false);
    }, 2000); // wait 2s before paste again
  };

  return { handlePaste, handleMouseEnter, handleMouseLeave };
};

export default usePaste;
