import { AssetContentFieldStatus, type AssetPostForm } from "@src/types";
import {
  GeographicScope,
  Levels,
  ProductStatus,
} from "@utils/data/enums/generals.enum";

export const X_ID_TOKEN = "x-id-token";

export const X_API_KEY = "x-api-key";

export const X_ACCESS_TOKEN = "x-access-token";

export const OKTA_API_KEY = "xapikey";

export const MAKE_UP_AXIS_ID = 1;
export const CARE_AND_BTG_AXIS_ID = 2;
export const ACCESSORIES_AXIS_ID = 3;
export const SARDINES_AXIS_ID = 4;
export const MEDIA_ALWAYS_ON_AXIS_ID = 5;
export const HOLIDAY_AXIS_ID = 5;

export const LevelOfActivationOption = [
  { value: Levels.BIGBET, label: "Big Bet" },
  { value: Levels.STRATEGIC, label: "Strategic" },
  { value: Levels.CLASSIC, label: "Standard" },
  { value: Levels.PILLARS, label: "Pillar" },
];

export const DISPLAY_DATE_FORMAT = "DD/MM/YYYY";

export const StatusOption = [
  {
    value: ProductStatus.ONE_SHOT,
    label: "One shot",
  },
  {
    value: ProductStatus.CATALOGUE,
    label: "Catalogue",
  },
  {
    value: ProductStatus.CATALOGUE_1_YEAR,
    label: "Catalogue 1 year",
  },
];

export const GeographicScopeOption = [
  {
    value: GeographicScope.CHINA,
    label: "CN",
  },
  {
    value: GeographicScope.NORTHAMERICA,
    label: "NAM",
  },
];

export const initAsset: Partial<AssetPostForm> = {
  new_tag: [],
  comment: { value: "", status: AssetContentFieldStatus.NULL },
  nb_of_shades_to_shoot: { value: "", status: AssetContentFieldStatus.NULL },
  nb_of_carnations: { value: "", status: AssetContentFieldStatus.NULL },
  total_nb_of_assets: { value: "", status: AssetContentFieldStatus.NULL },
  skus: { value: "", status: AssetContentFieldStatus.NULL },
  name_of_shades: { value: "", status: AssetContentFieldStatus.NULL },
  comment_from_marketing_product: {
    value: "",
    status: AssetContentFieldStatus.NULL,
  },
  geographic_scope: [],
  ppage: [],
  digital_animation: [],
  media_regional_context: { value: "", status: AssetContentFieldStatus.NULL },
  media_traditional_tv_dooh_ooh: [],
  media_digital: [],
  permanent_instore: [],
  temporary_instore: [],
};

export const possibleValues = {
  geographic_scope: [
    "WW",
    "NAM",
    "LATAM",
    "EU",
    "MEAST",
    "SEA",
    "CN",
    "WW exc. CN",
    "WW exc. NAM",
  ],
  ppage: [
    "1500",
    "2000",
    "INFOGRAPHIC User test or clinical test",
    "INFOGRAPHIC key benefits",
    "INFOGRAPHIC Battle",
    "INFOGRAPHIC Routine",
    "INFOGRAPHIC Ingredient",
    "INFOGRAPHIC 3D",
    "MP4",
  ],
  digital_animation: [
    "Window desktop 2880 x 892",
    "Window mobile 750 x 1160",
    "Underbanner 686 x 434",
    "OLD Beauty tips 684 x 742",
    "NEW Beauty tips 912 x 608",
    "Brandorama 508 x 224",
    "Push Web 1000x500",
    "Tile 400x700 ",
    "PLP Banner 1440 x 280",
    "Marketing banner 792 x 244",
    "Beauty deals 645 x 480",
    "Brandspace intro 900 x 575",
    "App card 890 x 1135",
    "App category bloc 352 x 264",
    "Push in app 1200 x 600",
    "Footer email 1280 x 400",
    "Hero carousel tablet 1142 x 928",
    "Hero banner desk 1472 x 996",
    "Hero banner mob 686 x 852",
    "Hero banner tab 1472 x 1152",
    "Spotlight 1472 x 1456 ",
    "Brand carousel desk 400 x 672",
    "Brand banner	 1123x300",
    "Content banner	 730x760",
    "App brand banner 	1080x608",
    "Desktop overlay	 800x500 ",
    "Big banner desk 545 x 363",
    "Big banner mobile 315 x 209",
    "Double trending banner 600 x 450",
    "Brandspace banner 689 x 264",
    "Slider desktop 1280 x 448",
    "Slider mobile & app 600 x 717",
    "Footer banner 368 x 265",
    "Trending banner 404 x 300",
    "SBC Off-site 300x250",
    "SBC Off-site 320x50",
    "SBC Off-site 300x600",
    "SBC On-site 970x90",
    "SBC On-site 160x600",
    "BSL Homepage banner 1200x800",
    "BSL Email banner 700x350",
  ],
  media_traditional_tv_dooh_ooh: [
    "Outdoor GB",
    "Outdoor FR",
    "MAIN VIDEO – GB // 20 SEC FOR TV",
    "MAIN VIDEO – FR // 20 SEC FOR TV",
    "MAGAZINE PRINT A4",
    "ADVERTORIAL",
  ],
  media_digital: [
    "MAIN VIDEO – GB // 15 SEC FOR DIGITAL AND SOCIAL",
    "MAIN VIDEO – GB // 10 SEC FOR SOCIAL AND DOH",
    "MAIN VIDEO – GB // 6 SEC FOR DIGITAL",
    "MAIN VIDEO – FR // 15 SEC FOR DIGITAL AND SOCIAL",
    "MAIN VIDEO – FR // 10 SEC FOR SOCIAL AND DOH",
    "MAIN VIDEO – FR // 6 SEC FOR DIGITAL",
    "EUR DIGITAL BANNERS – GB",
    "EUR DIGITAL BANNERS – FR",
    "US DIGITAL BANNERS – GB",
    "SOCIAL MEDIA – BRANDED MOTION ASSETS",
    "SOCIAL MEDIA MOVIES – UGV CONTENT CREATORS ASSETS",
    "SOCIAL MEDIA – BRANDED STATIC ASSETS",
    "EMAILING",
    "SOCIAL MEDIA STATIC FEED",
    "SOCIAL MEDIA IG STORY",
    "NAM SBC Off-site 300x250",
    "NAM SBC Off-site 320x50",
    "NAM SBC Off-site 300x600",
  ],
  permanent_instoreMupAccessSardine: [
    "EME + SEA + CN, MUP, COLOR21, Visual Podium, 580x374mm",
    "EME + SEA + CN, MUP, COLOR21, Zone Focus, 296x230mm",
    "EME + SEA + CN, MUP, COLOR21, Module Visual, 146,4mm x 163mm",
    "EME + SEA + CN, MUP, COLOR21, Post-It, 46mm x 71,5mm",
    "EME + SEA + CN, MUP, COLOR21, Before/ After Module, 81x81mm",
    "EME + SEA + CN, MUP, COLOR21, Before/ After Podium, 70x 70mm",
    "SEA, MUP, COLOR21, NSC Screen, 1884x520mm",
    "NAM, MUP, COLOR, Main Focus Unit, Main Panel, 605,02x478,02mm",
    "NAM, MUP, COLOR, Main Focus Unit, Merchandiser, 316,33x477,26mm",
    "NAM, MUP, COLOR, Main Focus Unit, SUB Focus Visual, 148,84x316,48mm",
    "NAM, MUP, COLOR, Center Visual, 492,125x492,125mm",
    "NAM, ACCESS, COLOR, Cover visual, 46,3296 cm x 85,2424 cm",
    "US/CAN/QUE, MUP, TIP , Tip, 429,125x 429,125mm",
    "US/CAN/QUE, MUP, FLEET, Fleet, 946,15x1841,5mm",
    "US/CAN/QUE, MUP, FLEET, Fleet, 946,15x1598,6125mm",
    "US/CAN/QUE, MUP, FLEET, Fleet, 946,15x926,2115mm",
    "US/CAN/QUE, MUP, FLEET, Main Center pannel, 926,15x1226,0072mm",
    "US/CAN/QUE, MUP, FLEET, Fleet, 926,15x1171,575mm",
    "US ONLY, MUP, FLEET, Fleet, 946,15x1353,0072mm",
    "US ONLY, MUP, FLEET, Main Center pannel, 946,15x1130,3mm",
    "US ONLY, MUP, FLEET, Fleet, 946,15x971,55mm",
    "NAM, MUP, PLUTO, MFU Visual, 624,586mm x 403,352mm",
    "NAM, MUP, PLUTO, Vertical Visual, 193,802mm x1166,622",
    "NAM, MUP, PLUTO, SUB Focus Visual, 101,6mm x 203,2mm / 156,464mm x 203,2mm",
    "NAM, MUP, PLUTO, Top shelf MFU + Merchandiser, 234,95mm x 357,124mm",
    "NAM, MUP, PLUTO, Top shelf MFU + Tester, 368,3mm x 357,124mm",
    "EU + SEA + UK, ACCESS, Pro Brush Endcap, Pro Brush Endcap, 287x168mm",
    "EME + UK , ACCESS, Premium C2, Premium C2 Didactic Visual, 223 x 245 mm",
    "EME + UK , ACCESS, Premium C2, Premium C2 Visual, 403mm x 172mm",
    "EU + UK, ACCESS, Standard C1, Standard C1 Visual, 103,6 x 179 mm",
    "EU + UK, ACCESS, Standard C2, Standard C2 Visual, 207,2 x 179 mm",
    "EU, ACCESS, Brush & Tools Endcap, Brush & Tools Endcap, 167x146,75mm",
    "EU + UK  , ACCESS, 1ML ACCESS & TOOLS, Visual Lightbox, 698 mm x 206,6 mm",
    "EU + UK  , ACCESS, 1ML ACCESS & TOOLS, Visual Column , 191 mm x 391 mm",
    "EU + UK  , ACCESS, 1ML ACCESS & TOOLS, Visual on shelf (TBC), 164 mm x 215 mm",
    "EU + UK  , ACCESS, 1ML ESSENTIAL BRUSHES , Visual Lightbox, 698 mm x 206,6 mm",
    "EU + UK  , ACCESS, 1ML ESSENTIAL BRUSHES , Visual Column , 191 mm x139 mm",
    "CN ? , ACCESS, 1ML PRO BRUSHES , Visual Lightbox, 698 mm x 206,6 mm",
    "EU + UK + SEA + MEAST + SEA + CN , ACCESS, 1ML PRO BRUSHES , Visual Column , 191 mm x 391 mm",
    "EU + UK + SEA + MEAST + SEA + CN , ACCESS, 1ML PRO BRUSHES & PRO ENDCAP, Visual Stock FOUNDATION FACE, 226 mm x 224 mm",
    "EU + UK + SEA + MEAST + SEA + CN , ACCESS, 1ML PRO BRUSHES & PRO ENDCAP, Visual Stock COLOR FACE, 226 mm x 224 mm",
    "EU + UK + SEA + MEAST + SEA + CN , ACCESS, 1ML PRO BRUSHES , Visual stock EYES, 226 mm x 224 mm",
    "EU + UK  , ACCESS, 1ML ESSENTIAL BRUSHES & ACCESS TOOLS , Visual Lightbox Essential brushes , 470 mm x 210 mm (TBC)",
    "EU + UK  , ACCESS, 1ML ESSENTIAL BRUSHES & ACCESS TOOLS , Visual Lightbox Accessories & Tools , 470 mm x 210 mm (TBC)",
    "EU + UK + SEA + MEAST + SEA, ACCESS, EDG PRO , Visual Lightbox, 575 mm x 215 mm",
    "EU , ACCESS, EDG CORNER ESSENTIAL BRUSHES & ACCESS TOOLS, Visual Lightbox Essential brushes , 575 mm x 215 mm",
    "SEA, ACCESS, 1ML ACCESS & TOOLS, Visual Lightbox, 712 mm x 191 mm",
    "SEA, ACCESS, 1ML ACCESS & TOOLS, Visual Column , 193 mm x 370,5 mm",
    "SEA, ACCESS, 1ML ESSENTIAL BRUSHES , Visual Lightbox, 712 mm x 191 mm",
    "SEA, ACCESS, 1ML ESSENTIAL BRUSHES , Visual Column , 191 mm x139 mm",
    "SEA, ACCESS, 1ML ESSENTIAL BRUSHES & ACCESS TOOLS , Visual Lightbox Essential brushes , 442 mm x 191 mm",
    "SEA, ACCESS, 1ML ESSENTIAL BRUSHES & ACCESS TOOLS , Visual Lightbox Accessories & Tools , 442 mm x 191 mm",
  ],
  permanent_instoreCare: [
    "SEA, SKINCARE GSK, Gondola 2.0 2ML, Center visual podium, 593x273mm (visible 575x255mm)",
    "SEA, SKINCARE GSK, Gondola 2.0 2ML, Newness ingredients , 297x250mm (visible 297x236mm)",
    "SEA, SKINCARE GSK, Gondola 2.0 2ML, Newness visual, 271x256mm (visible 263x248mm)",
    "SEA, SKINCARE GSK, Gondola 2.0 2ML, Tester podium, 375x115mm",
    "SEA, SKINCARE GSK, Gondola 2.0 2ML, Name podium, 590x30mm",
    "CN , SKINCARE GSK, Gondola 2.0 2ML, Center visual podium, 612x292mm (visible 570x285,03mm)",
    "CN , SKINCARE GSK, Gondola 2.0 2ML, Newness ingredients , 316x269mm (visible 299,44x299,44mm)",
    "CN , SKINCARE GSK, Gondola 2.0 2ML, Newness visual, 300x350mm (visible 290x275mm)",
    "EU + UK, SKIN+, L1/L2, Main visual, L310mm*H513mm",
    "EU + UK, SKIN+, L1/L2/C1/C2, Small visuals , L100mm*H179mm",
    "EU + UK, SKIN+, L1/L2/C1/C2, Sephora glorifiers , L100mm*H159mm",
    "EU + UK, SKIN+, L1/L2/C1/C2, Niche - Fond, 695x150mm",
    "EU + UK, SKIN+, L1/L2/C1/C2, Niche - Plat, L689mm*H115mm",
    "EU + UK, SKIN+, L1/L2/C1/C2, Niche - Top strip, L940mm*H79mm",
    "EU + UK, SKIN+, L1/L2/C1, Niche - Visual box, L180mm*H160mm",
    "EU + UK, SKIN+, L1/L2/C1/C2, Niche - Small visual, L120mm*H120mm",
    "EU + UK, SKIN+, C2, Main visual, L432mm*H502mm",
    "EU + UK, SKIN+, C1/C2, Background, L800mm*H913mm",
    "EU + UK, SKIN+, L1/L2, Background, L830mm*H1667mm",
    "NAM, SKIN+, L1, Center Panel, 492,252x492,252mm",
    "NAM, SKIN+, L1, MFU, L243xH228mm",
    "NAM, SKIN+, L1, SFU, L127xH165mm",
    "NAM, SKIN+, L1, Background Focus Zone, L940xH251mm",
    "NAM, SKIN+, SEMI-PERM ENDCAP, MFU, L228xH355mm",
    "NAM, SKIN+, SEMI-PERM ENDCAP, SFU, L127xH177mm",
    "EU + UK + SEA, SKINCARE COLORFUL, Premium Gondola 1ML, Packshot, 149 X 309mm (visible 134 x 294 mm)",
    "EU + UK + MEAST + SEA + CN, SKINCARE COLORFUL, Premium Gondola 2ML, Main visual, 621x328mm (visible 596 x 301 mm)",
    "EU + UK + MEAST + SEA + CN, SKINCARE COLORFUL, Premium Gondola 2ML, Packshot, 149 X 309mm (visible 134 x 294 mm)",
    "EU , SKINCARE COLORFUL, Standard Gondola 1ML, Packshot, L230 X H150",
    "EU , SKINCARE COLORFUL, Standard backwall 2ML, Packshot, L230 X H150",
    "EU , SKINCARE COLORFUL, Standard Gondola 2ML, Packshot, L230 X H150",
    "LATAM + NAM, SKINCARE COLORFUL, Standard backwall 1ML, MFU, L180 X H796 mm",
    "LATAM + NAM, SKINCARE COLORFUL, Standard backwall 1ML, SFU, L506 X H600 mm",
    "EU, SKINCARE BATH, Premium C1, Ingredient visual, L166 x H136 mm",
    "EU, SKINCARE BATH, Premium C1, Range visual, L500 x H58 mm",
    "EU, SKINCARE BATH, Premium C2, Central visual, L699 x H342 mm",
    "EU , SKINCARE BATH, Premium C2, Pouch visual, L169 x H139 mm",
    "EU, SKINCARE BATH, Premium C2, Main visual, L166 x H136 mm",
    "EU , SKINCARE BATH, Standard C1 , Ingredients visual, L166 x H136 mm",
    "EU, SKINCARE BATH, Standard L1 (backwall), Range visual, L169 x H139 mm",
    "EU, SKINCARE BATH, Standard L1 (backwall), Ingredients visual, L166 x H136 mm",
    "EU + SEA, SKINCARE BATH, Standard C1 , Range visual, L220 x H35 mm",
    "SEA, SKINCARE BATH, C1, Ingredients visual, L178x108,5 mm",
    "CN, SKINCARE BATH, NSC & Classic EDG , Ingredients visual, L114xH94 mm",
    "CN, SKINCARE BATH, NSC & Classic C1 , Ingredients visual, L172xH187 mm",
    "CN, SKINCARE BATH, NSC C1, Range visual, L307xH497 mm",
    "CN, SKINCARE BATH, Classic C1, Range visual, L931xH50 mm",
    "LATAM, SKINCARE BATH, Standard L1, Ingredients visual, L495xH495 mm",
    "LATAM, SKINCARE BATH, Standard L1, Range visual, L127xH177mm",
    "MEAST, HAIRCARE, PREMIUM ENDCAP, Main visual, L640mm*H320mm",
    "MEAST, HAIRCARE, PREMIUM ENDCAP, Back dummy, L256mm*H759mm",
    "SEA, HAIRCARE, C1 NSC, Visuals, L172mm*H187mm",
    "SEA, HAIRCARE, C1 CLASSIC, Visuals , L168mm*H184mm",
    "EUROPE, HAIRCARE, all configurations, Visuals , L120*H195mm",
    "EUROPE, HAIRCARE, BACKWALL 1LM STD, Top visual, L939*H335mm",
    "EUROPE, HAIRCARE, BACKWALL 1LM NSC, Top visual, L939*H285mm",
    "EUROPE, HAIRCARE, BACKWALL 1-2LM STD, Top visual, L469,5xH328mm",
    "EUROPE, HAIRCARE, BACKWALL 1-2LM NSC, Top visual, L469,5xH285mm",
    "CN, HAIRCARE, 1 SHELF PREMIUM, Main visual, L172mm*H187mm",
  ],
  temporary_instoreForMupAccessCareSardine: [
    "EDG, Caisson, 485mm x 1180mm x 220mm",
    "EDG, Joues, 382mm x 1350,6mm",
    "EDG, Header + Habillage du fond, 585mm x 1405mm",
    "EDG, Visuel marque, 318mm x 827mm",
    "EDG, Colonne, 240mm x 1005mm",
    "C2,  , 1900mm x 972mm",
    "C3,  , 2847mm x 972mm",
    "Beauty Hub Standard,  , 820mm x 725mm",
    "Beauty Hub Premium,  , 820mm x 800mm",
    "Cashtill,  , 30,1mm x 8,2mm",
    "EDG, Central visual, 340mm x 980mm",
    "EDG, Box shelf talker, 440mm x 640mm x 220mm",
    "EDG, 2 Wings visual, 380mm x 1375mm",
    "C2, Top visual, 1890mm x 215mm",
    "C2, 1st Shelf visual, 1890mm x 313mm",
    "C2, 2nd Shelf visual, 1890mm x 435mm",
    "C2, Bottom visual , 1890mm x 380mm",
    "C2 Animated, Video, 2160 x 3840",
    "C2 Animated, Background visual, 1346mm x 816,5mm",
    "C2 Animated, Bottom visual , 945mm x 380mm",
    "Secondary product artwork",
    "Window EU",
    "Security Gate EU",
  ],
  temporary_instoreHoliday: [
    "EU, EDG NSC, , 230 L x 1200 H mm",
    "SEA, EDG NSC, , 320 L x 790 H mm",
    "SEA, Security gates, , 497 L x 1515 H mm",
    "SEA, Security gates, ,   505 L x 1520 H mm",
    "SEA, Security gates, ,  295 L x 1520 H mm",
    "SEA, Security gates, , 400 L x 1525 H mm",
    "SEA, Beauty Hub, , 595 L x 875 H mm",
    "CN, Wings EDG , , 382 L x 1354 H mm",
    "NAM, Event Kit Kakemono , , 914 L x 1987 H mm",
  ],
};
