import { createSlice } from "@reduxjs/toolkit";

import { type SharedProps } from "notistack";
import { getRandomKey } from "utils/common.utils";

export type SnackbarProps = SharedProps & {
  message: string;
  translation?: boolean;
  key?: number;
  startedTimeStamp?: number;
};

export interface CommonState {
  displayMenu: boolean;
  displayBlocker: boolean;
  snackbars: SnackbarProps[];
}

const initialState: CommonState = {
  displayMenu: false,
  displayBlocker: false,
  snackbars: [],
};

export const commonReducer = createSlice({
  name: "common",
  initialState,
  reducers: {
    addSnackbar: (state, { payload }: { payload: SnackbarProps }) => {
      //Not ideal but hack to force Typescript to consider newSnackbar as a mutable copy
      const newSnackbar = JSON.parse(JSON.stringify(payload));
      newSnackbar.key = getRandomKey();
      state.snackbars.push(newSnackbar);
    },
    removeSnackbar: (state, { payload }: { payload: number }) => {
      state.snackbars = state.snackbars.filter(({ key }) => key != payload);
    },
    setDisplayBlocker: (state, { payload }: { payload: boolean }) => {
      state.displayBlocker = payload;
    },
    removeSnackbarFromMessage: (state, { payload }: { payload: string }) => {
      state.snackbars = state.snackbars.filter(
        ({ message }) => message != payload,
      );
    },
  },
});

export const {
  addSnackbar,
  removeSnackbar,
  removeSnackbarFromMessage,
  setDisplayBlocker,
} = commonReducer.actions;

export default commonReducer.reducer;
