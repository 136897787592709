import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { toRelativeUrl } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { useLazyGetAxisQuery } from "@store/api/axis";
import { useLazyGetCategoriesQuery } from "@store/api/categories";
import { useLazyGetSeasonsQuery } from "@store/api/season";
import { useLazyGetUsersQuery } from "@store/api/user";
import { setUser } from "@store/auth/auth.reducer";
import { useAppDispatch } from "@store/hooks";
import { getCredentials } from "@utils/common.utils";

const SecureRoute = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const dispatch = useAppDispatch();

  const [getAxis] = useLazyGetAxisQuery();
  const [getSeasons] = useLazyGetSeasonsQuery();
  const [getCategories] = useLazyGetCategoriesQuery();
  const [getUsers] = useLazyGetUsersQuery();
  
  const init = async () => {
    getAxis();
    getSeasons();
    getCategories();
    getUsers();
  };

  useEffect(() => {
    (async () => {
      if (!authState) {
        return;
      }

      const credentials = getCredentials(authState);

      if (!authState?.isAuthenticated) {
        const originalUri = toRelativeUrl(
          window.location.href,
          window.location.origin,
        );
        oktaAuth.setOriginalUri(originalUri);
        await oktaAuth.signInWithRedirect();
      } else if (credentials) {
        dispatch(setUser(credentials));
        await init();
      }
    })();
  }, [authState?.isAuthenticated, authState?.accessToken]);

  return <Outlet />;
};

export default SecureRoute;
