import React, { useCallback, useEffect, useMemo } from "react";
import type { FieldValues } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import type { BlockerFunction } from "react-router-dom";
import { useBeforeUnload, useBlocker } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { setDisplayBlocker } from "@store/common/common.reducer";
import { useAppDispatch } from "@store/hooks";
import type { RootState } from "@store/store";
import { areJsonEqual } from "@utils/fonctions.utils";

interface Props<T extends FieldValues> {
  initialValues: T;
  formValues: T;
  onBlurFields: (keyof T)[];
}

const BlockDialog = <T extends FieldValues>({
  initialValues,
  formValues,
  onBlurFields,
}: Props<T>) => {
  const dispatch = useAppDispatch();
  const displayBlocker = useSelector(
    (state: RootState) => state.common.displayBlocker,
  );

  const fieldsEditedCount = useMemo(
    () =>
      onBlurFields.reduce((previousValue, currentValue) => {
        if (
          !areJsonEqual(initialValues[currentValue], formValues[currentValue])
        ) {
          return previousValue + 1;
        }
        return previousValue;
      }, 0),
    [initialValues, formValues],
  );

  useEffect(() => {
    dispatch(setDisplayBlocker(fieldsEditedCount > 0));
  }, [fieldsEditedCount]);

  useBeforeUnload(
    useCallback(
      (e) => {
        if (displayBlocker) {
          e.preventDefault();
          e.returnValue = "";
        }
      },
      [displayBlocker],
    ),
  );

  const shouldBlock: BlockerFunction = ({ currentLocation, nextLocation }) => {
    return displayBlocker && currentLocation.pathname !== nextLocation.pathname;
  };

  const blocker = useBlocker(shouldBlock);

  const handleCancel = () => blocker?.reset && blocker.reset();

  const handleConfirm = async () => {
    dispatch(setDisplayBlocker(false));
    if (blocker?.proceed) {
      blocker.proceed();
    }
    if (blocker?.reset) {
      blocker.reset();
    }
  };

  return (
    <Dialog open={blocker.state === "blocked"} onClose={handleCancel}>
      <DialogTitle>
        <FormattedMessage id="common.modal.unsaved.title" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id="common.modal.unsaved.body" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          <FormattedMessage id="common.back" />
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          <FormattedMessage id="common.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BlockDialog;
