import React from "react";

import { Box, Popover, styled } from "@mui/material";
import type { SxProps } from "@mui/system";

const PopoverWrapper = styled(Popover)({
  pointerEvents: "none",
});

const PopoverContent = styled(Box)({
  pointerEvents: "auto",
  maxWidth: "70vw",
  maxHeight: "50vh",
  overflowY: "auto",
});

interface Props {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  anchorRef: React.MutableRefObject<HTMLDivElement | null>;
  children: React.ReactNode;
  isOpen: boolean;
  keepOpenOnContent?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  sx?: SxProps;
}

const PreviewPopover: React.FC<Props> = ({
  children,
  onMouseEnter,
  anchorRef,
  onMouseLeave,
  isOpen,
  keepOpenOnContent = false,
  onClick,
  sx,
}) => {
  const handleMouseEnter = async () => {
    keepOpenOnContent && onMouseEnter();
  };

  const handleMouseLeave = () => {
    onMouseLeave();
  };

  return (
    <PopoverWrapper
      open={isOpen}
      anchorEl={anchorRef.current}
      disableScrollLock
      disableRestoreFocus
      disableAutoFocus //BAS-435
      onClick={onClick}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <PopoverContent
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={sx}
      >
        {children}
      </PopoverContent>
    </PopoverWrapper>
  );
};

export default PreviewPopover;
