import React, { type FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { Button, Grid, Typography } from "@mui/material";
import { useUnlinkAssetMutation } from "@store/api/asset";

interface Props {
  onClose: () => void;
  id: number;
}

const UnlinkAssetModal: FC<Props> = ({ onClose, id }) => {
  const [unlinkAsset, { isLoading, isSuccess }] = useUnlinkAssetMutation();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess]);

  const handleClickConfirm = () => {
    unlinkAsset({ id });
  };

  return (
    <Grid container my={2}>
      <Typography>
        <FormattedMessage id="common.unlink.modal.desc.label" />
      </Typography>
      <Grid
        item
        display="flex"
        justifyContent="space-between"
        width="100%"
        mt={4}
      >
        <Button onClick={onClose} variant="outlined">
          <FormattedMessage id="common.back" />
        </Button>

        <Button
          disabled={isLoading}
          type="submit"
          variant="contained"
          onClick={handleClickConfirm}
        >
          <FormattedMessage id="common.confirm.delete" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default UnlinkAssetModal;
