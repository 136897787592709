import type { AssetPutRequestDto } from "@src/dto/assetPutRequest.dto";
import type { ProductPutRequestDto } from "@src/dto/productPutRequest.dto";
import type { SeasonPutRequestDto } from "@src/dto/seasonPutRequest.dto";
import type {
  AssetFileValueResponse,
  AssetPostRequest,
  AssetValue,
} from "@src/types";
import { filterNonEmptyAssetContent } from "@utils/fonctions.utils";

import type { ProductPostBodySimpleField } from "types/api/product";

export const createFormDataSeason = (data: SeasonPutRequestDto) => {
  const { merchAnnexes, ...body } = data;
  const formData = new FormData();
  if (merchAnnexes && merchAnnexes.length > 0) {
    merchAnnexes?.forEach((file) => {
      formData.append(`merchAnnexes`, file);
    });
  }
  Object.entries(body).forEach(([key, value]) =>
    formData.append(key, `${value}`),
  );

  return formData;
};
export const createFormDataProduct = (
  data: ProductPostBodySimpleField | ProductPutRequestDto,
) => {
  const { assortmentFile, packshots, additionalPhotos, ...body } = data;
  const formData = new FormData();

  if (packshots && packshots.length > 0) {
    packshots.forEach((file) => {
      formData.append("packshots", file);
    });
  }

  if (additionalPhotos && additionalPhotos.length > 0) {
    additionalPhotos.forEach((file) => {
      formData.append("additionalPhotos", file);
    });
  }

  if (assortmentFile && assortmentFile instanceof Blob) {
    formData.append("assortmentFile", assortmentFile);
  }

  Object.entries(body).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          formData.append(`${key}[]`, item);
        });
      } else {
        formData.append(key, `${value}`);
      }
    }
  });

  return formData;
};

export const createFormDataAsset = (data: AssetPutRequestDto) => {
  const { bench_visuals, ...body } = data;
  const formData = new FormData();
  const modifiedBenchVisual: AssetValue[] = [];
  if (bench_visuals && bench_visuals.length > 0) {
    bench_visuals?.forEach((file) => {
      file.value instanceof File
        ? formData.append("bench_visuals", file.value)
        : modifiedBenchVisual.push({
            value: (file as AssetFileValueResponse).value.name,
            status: file.status,
          });
    });
  }
  // in the case where we updated deleted bench visuals
  const newBody =
    modifiedBenchVisual.length > 0
      ? ({
          ...body,
          bench_visuals: modifiedBenchVisual,
        } as AssetPutRequestDto)
      : body;
  formData.append("content", JSON.stringify(newBody));

  return formData;
};

export const createPostFormDataAsset = (data: AssetPostRequest) => {
  const { content, ...body } = data;
  // filter not filled values otherwise we will send empty objects and backend won't trigger default values if exist
  const filledContent = filterNonEmptyAssetContent(content);
  const { bench_visuals, ...filledContentRest } = filledContent;
  const formData = new FormData();
  if (bench_visuals && bench_visuals.length > 0) {
    bench_visuals?.forEach((file) => {
      formData.append(`bench_visuals`, file);
    });
  }
  formData.append("content", JSON.stringify(filledContentRest));

  Object.entries(body).forEach(([key, value]) =>
    formData.append(key, `${value}`),
  );
  return formData;
};
