import React, { type FC, useEffect, useState } from "react";
import { type FieldPath, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath, useNavigate } from "react-router-dom";

import GenericFormField from "@components/Inputs/GenericFormField";
import { Button, Divider, Grid } from "@mui/material";
import type { SeasonDetailsRequest, SeasonForm } from "@src/types";
import { FIELD_TYPES, type FieldConfig, SEASON_TYPES } from "@src/types";
import { useLazyGetAxisWithSeasonQuery } from "@store/api/axis";
import {
  useGetSeasonsTypeQuery,
  usePostSeasonMutation,
} from "@store/api/season";
import { selectAxisType } from "@store/axis/axis.selector";
import { useAppSelector } from "@store/hooks";
import {
  cleanString,
  generateYearsOptions,
  getIdFromName,
} from "@utils/fonctions.utils";

import dayjs from "dayjs";

interface Props {
  onClose: () => void;
}

const AddSeasonDialog: FC<Props> = ({ onClose }) => {
  const navigate = useNavigate();
  const [isUpdatedName, setIsUpdatedName] = useState(false);

  const axisTypes = useAppSelector(selectAxisType);

  const { data: seasonTypes = [] } = useGetSeasonsTypeQuery();
  const [getAxis, { data: axisOptions }] = useLazyGetAxisWithSeasonQuery({
    selectFromResult: ({ data }) => ({
      data:
        data?.map((axisType) => ({
          value: axisType.name,
          label: axisType.name,
        })) || [],
    }),
  });
  const [postSeason, { isLoading, isSuccess }] = usePostSeasonMutation();

  const seasonOption =
    seasonTypes?.map((season) => {
      return { value: season.name, label: season.name };
    }) || [];

  const {
    formState: { isValid },
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
  } = useForm<SeasonForm>({
    mode: "onBlur",
  });

  const { year, season, axis, name, deadlineDef, deadlineNoDef } = watch();

  const generateSeasonalBriefName = () => {
    switch (season) {
      case SEASON_TYPES.HOLIDAY:
      case SEASON_TYPES.MEDIA_ALWAYS_ON: {
        return `${season?.toUpperCase()}${year ? year?.slice(-2) : ""}`;
      }
      case SEASON_TYPES.SPRING:
      case SEASON_TYPES.SUMMER:
      case SEASON_TYPES.FALL:
      default: {
        return `${season?.slice(0, 2)}${year ? year?.slice(-2) : ""} ${axis ? axis : ""}`;
      }
    }
  };

  useEffect(() => {
    if (!isUpdatedName && (season || year || axis)) {
      setValue("name", generateSeasonalBriefName(), { shouldValidate: true });
    }
  }, [year, season, axis, isUpdatedName, setValue]);

  useEffect(() => {
    (async function fetchAxis() {
      if (season && seasonTypes) {
        const { data } = await getAxis({
          seasonType: getIdFromName(season, seasonTypes),
        });
        if (data?.length === 1) {
          setValue("axis", data[0].name, { shouldValidate: true });
        }
      }
    })();
  }, [season]);

  useEffect(() => {
    if (isSuccess) {
      const redirectWithParams = generatePath("season/:cleanName", {
        cleanName: cleanString(name) || null,
      });
      navigate(redirectWithParams);
    }
  }, [isSuccess]);

  const handleChangeValue = (fieldName: FieldPath<SeasonForm>) => {
    if (fieldName === "name") {
      setIsUpdatedName(true);
    }
  };

  const onSubmit = async (data: SeasonForm) => {
    const seasonData: SeasonDetailsRequest = {
      name:
        data.name === "" || data.name.trim().length === 0 ? season : data.name,
      year: data.year,
      deadlineDef: data.deadlineDef.toDate(),
      deadlineNoDef: data.deadlineNoDef.toDate(),
      seasonTypeId: getIdFromName(data.season, seasonTypes),
      axisId: getIdFromName(data.axis, axisTypes),
    };
    postSeason(seasonData);
  };

  const formConfig: FieldConfig<SeasonForm>[] = [
    {
      fieldName: "season",
      label: "season",
      fieldType: FIELD_TYPES.SELECT,
      options: seasonOption,
      mandatory: true,
    },
    {
      fieldName: "year",
      label: "year",
      fieldType: FIELD_TYPES.SELECT,
      options: generateYearsOptions(),
      mandatory: true,
    },

    {
      fieldName: "axis",
      label: "axis",
      fieldType: FIELD_TYPES.SELECT,
      options: axisOptions,
      mandatory: true,
    },
    {
      fieldName: "name",
      label: "Name",
      fieldType: FIELD_TYPES.INPUT_TEXT,
      mandatory: true,
      onChange: () => handleChangeValue("name"),
    },
    {
      fieldName: "deadlineNoDef",
      label: "deadlineNoDef",
      fieldType: FIELD_TYPES.DATE,
      mandatory: true,
      maxDate: deadlineDef,
      minDate: dayjs(),
    },
    {
      fieldName: "deadlineDef",
      label: "deadlineDef",
      fieldType: FIELD_TYPES.DATE,
      mandatory: true,
      minDate: deadlineNoDef,
    },
  ];

  return (
    <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
      <Grid container display="flex" justifyContent="space-between">
        <Grid item flexDirection="column" display="flex" flex={1}>
          {formConfig
            .filter((field) => field.fieldType !== FIELD_TYPES.DATE)
            .map((field) => (
              <Grid item key={field.fieldName} my={1} sx={{ width: "100%" }}>
                <GenericFormField
                  field={field}
                  setValue={setValue}
                  register={register}
                  trigger={trigger}
                  control={control}
                  watch={watch}
                />
              </Grid>
            ))}
        </Grid>

        <Divider
          orientation="vertical"
          flexItem
          variant="middle"
          sx={{ mx: 2 }}
        />

        <Grid item display="flex" flexDirection="column" flex={1}>
          {formConfig
            .filter((field) => field.fieldType === FIELD_TYPES.DATE)
            .map((field) => (
              <Grid item key={field.fieldName} my={1} sx={{ width: "100%" }}>
                <GenericFormField
                  field={field}
                  setValue={setValue}
                  trigger={trigger}
                  register={register}
                  control={control}
                  watch={watch}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid item display="flex" justifyContent="space-between" mt={2}>
        <Button onClick={onClose} variant="outlined">
          <FormattedMessage id="common.back" />
        </Button>
        <Button
          type="submit"
          variant="contained"
          disabled={isLoading || !isValid}
        >
          <FormattedMessage id="season.modal.confirm" />
        </Button>
      </Grid>
    </form>
  );
};

export default AddSeasonDialog;
